import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";
import "../../../assets/css/overview-addnew-milestones.css";
import {
  fetchEnumOptions,
  fetchProjectTeamMembers,
} from "../../../utils/fetchData";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import {
  calculateDateDiff,
  formatDate,
  selectLoader,
  validateMilestoneFields,
} from "../../../utils/Helpers";
import APIServices from "../../../services/APIServices";
import { useNavigate } from "react-router-dom";

const AddTemplate = ({
  selectedTemplateId = "",
  setSelectedTemplateId,
  projectDetails,
  companyId = "",
  setMilestoneProgress,
}) => {
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [error, setError] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [errors, setErrors] = useState({}); // Track errors for milestones
  const [daysOptions] = useState([
    { value: 7, label: "7" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
  ]);
  const [totalDuration, setTotalDuration] = useState(null);
  const [templateError, setTemplateError] = useState("");
  const [signOffOptions, setSignOffOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [initialTemplateName, setInitialTemplateName] = useState("");
  const [initialMilestones, setInitialMilestones] = useState([]);
  const [isUpdateEnable, setUpdateEnable] = useState(true);
  const [milestones, setMilestones] = useState([
    {
      assigneeId: "",
      name: "",
      description: "",
      daysToComplete: null,
      signOffRequired: true,
      signOffTime: "",
      feedbackRequest: true,
      feedbackRequestTime: "",
      paymentDue: null,
      paymentDueRequired: true,
    },
  ]);
  const [milestonestoDelete, setMilestonesToDelete] = useState([]);

  useEffect(() => {
    if (projectDetails) {
      setProjectId(projectDetails._id);
      setStartDate(
        new Date(projectDetails.startDate).toLocaleDateString("en-CA")
      );
      setEndDate(new Date(projectDetails.endDate).toLocaleDateString("en-CA"));
      fetchProjectTeamMembers(projectDetails._id, setAssigneeOptions);
    }
  }, [projectDetails]);

  useEffect(() => {
    fetchEnumOptions("milestonetime", setSignOffOptions);
    // fetchEnumOptions("milestonpriority", setPriorityOptions);
  }, []);

  useEffect(() => {
    const dateDiff = calculateDateDiff(startDate, endDate);
    setTotalDuration(dateDiff);
    if (selectedTemplateId) {
      setTemplateId(selectedTemplateId);
      fetchTemplateData(selectedTemplateId);
      setEditing(true);
    }
  }, [selectedTemplateId]);

  const fetchTemplateData = async (templateId) => {
    setPageLoading(true);
    try {
      const response = await APIServices.get(`/template/${templateId}`); // Adjust the URL as necessary
      if (response.milestones) {
        setMilestones(response.milestones);
        setTemplateName(response.name);
        setInitialMilestones(response.milestones);
        setInitialTemplateName(response.name);
        setUpdateEnable(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setPageLoading(false);
    }
  };

  const checkMilestoneDiff = () => {
    if (
      milestones == initialMilestones &&
      initialTemplateName == templateName
    ) {
      setUpdateEnable(false);
    } else {
      setUpdateEnable(true);
    }
  };

  useEffect(() => {
    checkMilestoneDiff();
  }, [milestones, templateName]);

  const addMilestone = () => {
    if (validateMilestoneFields(milestones, setErrors, true)) {
      setMilestones([
        ...milestones,
        {
          assigneeId: "",
          name: "New Milestone",
          description: "",
          daysToComplete: 0,
          signOffRequired: false,
          signOffTime: "",
          feedbackRequest: false,
          feedbackRequestTime: "",
          paymentDue: 0,
          paymentDueRequired: false,
        },
      ]);
    }
  };

  const deleteMilestone = (index, milestoneId = "") => {
    const updatedMilestones = milestones.filter((_, i) => i !== index);
    setMilestones(updatedMilestones);

    if (milestoneId) {
      setMilestonesToDelete([...milestonestoDelete, milestoneId]);
    }
  };

  const handleInputChange = (index, field, value) => {
    const dateDiff = calculateDateDiff(startDate, endDate);
    setTotalDuration(dateDiff);
    // If modifying the daysToComplete, ensure it's within the allowed range
    if (field === "daysToComplete" && value > dateDiff) {
      appToast(
        `Days of completion cannot exceed the allowed limit of ${dateDiff} days.`,
        TOAST_TYPE.ERROR
      ); // Show an alert or handle error
      return; // Prevent updating if validation fails
    }
    const updatedMilestones = milestones.map((milestone, i) =>
      i === index ? { ...milestone, [field]: value } : milestone
    );
    setMilestones(updatedMilestones);
    // Clear errors for the current field when it's updated
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: { ...prevErrors[index], [field]: false },
    }));
  };

  const getProgressPercentage = (daysCompleted) => {
    // Calculate progress percentage
    const dateDiff = calculateDateDiff(
      projectDetails.startDate,
      projectDetails.endDate
    );

    if (!dateDiff || !daysCompleted) return 0;
    return Math.min((daysCompleted / dateDiff) * 100, 100).toFixed(0);
  };

  const backToTemplate = () => {
    setSelectedTemplateId("");
    setMilestoneProgress("select");
    setEditing(false);
  };

  const validateName = () => {
    let hasError = false;
    if (!templateName) {
      setTemplateError("Please enter Template name.");
      hasError = true;
    }
    return hasError;
  };

  const createProject = async (saveTemplate = false) => {
    if (validateName()) {
      return;
    }
    if (validateMilestoneFields(milestones, setErrors, saveTemplate)) {
      if (saveTemplate) {
        setLoadingTemplate(true);
      } else {
        setLoading(true);
      }
      let milestoneData = milestones.map((milestone) => ({
        name: milestone.name,
        description: milestone.description,
        startDate: startDate,
        daysToComplete: Number(milestone.daysToComplete),
        signOffTime: milestone.signOffTime,
        signOffRequired: milestone.signOffRequired,
        feedbackRequestTime: milestone.feedbackRequestTime,
        feedbackRequest: milestone.feedbackRequest,
        assigneeId: milestone.assigneeId || "",
        paymentDue: milestone.paymentDue,
        paymentDueRequired: milestone.paymentDueRequired,
        projectId: projectId,
      }));

      let saveTemplatePayload = {
        name: templateName, // Use the template name from state
        companyId: companyId || "",
        milestones: milestones.map((milestone) => ({
          ...(milestone._id && { _id: milestone._id }),
          name: milestone.name,
          description: milestone.description,
          daysToComplete: Number(milestone.daysToComplete),
          signOffTime: milestone.signOffTime,
          signOffRequired: milestone.signOffRequired,
          feedbackRequestTime: milestone.feedbackRequestTime,
          feedbackRequest: milestone.feedbackRequest,
          paymentDue: milestone.paymentDue,
          paymentDueRequired: milestone.paymentDueRequired,
          // projectId: projectId,
        })),
      };

      try {
        const templateResponse = isEditing
          ? await APIServices.put(
              `/template/${templateId}`,
              saveTemplatePayload
            )
          : await APIServices.post("/template", saveTemplatePayload);
        if (templateResponse.message) {
          if (saveTemplate) {
            appToast(templateResponse.message, TOAST_TYPE.SUCCESS);
            setMilestoneProgress("select");
          }
        } else {
          appToast(templateResponse.data.message, TOAST_TYPE.ERROR);
        }
        if (!saveTemplate) {
          const response = await APIServices.post(
            "/project-milestone",
            milestoneData
          );
          // console.log(response);
          if (response.message) {
            appToast(response.message, TOAST_TYPE.SUCCESS);
            navigate("/");
          } else {
            appToast(response.data.message, TOAST_TYPE.ERROR);
          }
        }
      } catch (error) {
        console.error("Form submission error:", error);
      } finally {
        setLoading(false);
        setLoadingTemplate(false);
      }
    }
    // Make API call or handle the payload as needed
  };

  if (pageLoading) {
    return <div className="nodata-loader"> {selectLoader(100)}</div>;
  }

  return (
    <div className="milestone-add pt-lg-5 mb-3">
      <div className="row">
        <div className="col-lg-7 col-md-12 pb-3">
          <div className="scroll-smooth">
            <h2 className="text-center mb-4">Milestones</h2>
            <div className="card-bg-white">
              <h4 className="text-center mt-sm-4">Write your template name</h4>
              <div className="add-milestone p-0 mb-4 br-10">
                <div className="mb-3 form-cover">
                  <input
                    type="text"
                    className={`form-control ${
                      templateError ? "form-invalid" : ""
                    } `}
                    placeholder="Enter Template name"
                    value={templateName}
                    onChange={(e) => {
                      setTemplateName(e.target.value);
                      setTemplateError("");
                    }}
                  />
                  {templateError && (
                    <div className="error-message">{templateError}</div>
                  )}
                </div>
              </div>

              {milestones.map((milestone, index) => (
                <div key={index}>
                  <h3 className="text-center mb-3 mb-md-5 mt-sm-4">
                    Milestone {index + 1}
                  </h3>
                  <form className="add-milestone p-0 mb-4 br-10">
                    <div className="mb-3 form-cover">
                      <input
                        type="text"
                        className={`form-control ${
                          errors[index]?.name ? "form-invalid" : ""
                        }`}
                        placeholder="Enter milestone..."
                        value={milestone.name}
                        onChange={(e) =>
                          handleInputChange(index, "name", e.target.value)
                        }
                      />
                      {errors[index]?.name && (
                        <div className="error-message">
                          {errors[index]?.name}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 form-cover">
                      <textarea
                        className={`form-control ${
                          errors[index]?.description ? "form-invalid" : ""
                        }`}
                        placeholder="Description"
                        value={milestone.description}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                      {errors[index]?.description && (
                        <div className="error-message">
                          {errors[index]?.description}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 form-cover">
                      <div
                        className={`form-group-box-cover ${
                          errors[index]?.daysToComplete ? "form-invalid" : ""
                        }`}
                      >
                        <label className="label-title d-flex align-items-center mb-0 me-3">
                          Days of completion
                        </label>
                        <input
                          type="text"
                          placeholder="Days"
                          className={`form-payment `}
                          value={milestone.daysToComplete}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Allow only digits and decimal points
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (regex.test(value)) {
                              handleInputChange(index, "daysToComplete", value);
                            }
                          }}
                        />
                      </div>
                      {errors[index]?.daysToComplete && (
                        <div className="error-message">
                          {errors[index]?.daysToComplete}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 form-cover">
                      <div
                        className={` form-group-box-cover ${
                          errors[index]?.signOffTime ? "form-invalid" : ""
                        }`}
                      >
                        <div
                          className={`form-check checkbox label-title d-flex align-items-center me-3`}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`checkboxSignOff${index}`}
                            checked={milestone.signOffRequired}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "signOffRequired",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="form-check-label mb-0"
                            htmlFor={`checkboxSignOff${index}`}
                          >
                            Sign-off required
                          </label>
                        </div>
                        <Select
                          options={signOffOptions}
                          value={signOffOptions.find(
                            (option) => option.value === milestone.signOffTime
                          )}
                          onChange={(option) =>
                            handleInputChange(
                              index,
                              "signOffTime",
                              option.value
                            )
                          }
                        />
                      </div>
                      {errors[index]?.signOffTime && (
                        <div className="error-message">
                          {errors[index]?.signOffTime}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 form-cover">
                      <div
                        className={` form-group-box-cover ${
                          errors[index]?.feedbackRequestTime
                            ? "form-invalid"
                            : ""
                        }`}
                      >
                        <div className="form-check checkbox label-title d-flex align-items-center me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`checkboxFeedbackOff${index}`}
                            checked={milestone.feedbackRequest}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "feedbackRequest",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="form-check-label mb-0"
                            htmlFor={`checkboxFeedbackOff${index}`}
                          >
                            Feedback request
                          </label>
                        </div>
                        <Select
                          options={signOffOptions}
                          value={signOffOptions.find(
                            (option) =>
                              option.value === milestone.feedbackRequestTime
                          )}
                          onChange={(option) =>
                            handleInputChange(
                              index,
                              "feedbackRequestTime",
                              option.value
                            )
                          }
                        />
                      </div>
                      {errors[index]?.feedbackRequestTime && (
                        <div className="error-message">
                          {errors[index]?.feedbackRequestTime}
                        </div>
                      )}
                    </div>

                    <div className="mb-3 form-cover">
                      <div
                        className={` form-group-box-cover ${
                          errors[index]?.assigneeId ? "form-invalid" : ""
                        }`}
                      >
                        <label className="label-title d-flex align-items-center mb-0 me-3">
                          Assignee
                        </label>
                        <Select
                          options={assigneeOptions}
                          value={assigneeOptions.find(
                            (option) => option.value === milestone.assigneeId
                          )}
                          onChange={(option) =>
                            handleInputChange(index, "assigneeId", option.value)
                          }
                          styles={{
                            container: (base) => ({
                              ...base,
                              maxWidth: "150px",
                            }),
                          }}
                        />
                      </div>
                      {errors[index]?.assigneeId && (
                        <div className="error-message">
                          {errors[index]?.assigneeId}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 form-cover">
                      <div
                        className={`form-group-box-cover  ${
                          errors[index]?.paymentDue ? "form-invalid" : ""
                        }`}
                      >
                        <div className="form-check checkbox label-title d-flex align-items-center me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`checkboxPayment${index}`}
                            checked={milestone.paymentDueRequired}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "paymentDueRequired",
                                e.target.checked
                              )
                            }
                          />
                          <label
                            className="form-check-label mb-0"
                            htmlFor={`checkboxPayment${index}`}
                          >
                            Payment Due
                          </label>
                        </div>
                        <div className="form-payment-cover">
                          <input
                            type="text"
                            className="form-payment"
                            placeholder="$"
                            maxLength={40}
                            value={milestone?.paymentDue ? `$ ${milestone.paymentDue}`: ""}
                            onChange={(e) => {
                              let value = e.target.value;
                              const validValue = value.replace(/[^0-9.]/g, "");
                              // Allow only digits and decimal points
                              const regex = /^[0-9]*\.?[0-9]*%?$/;
                              if (regex.test(validValue)) {
                                handleInputChange(index, "paymentDue", validValue);
                              }
                            }}
                          />
                          {/* <p>%</p> */}
                        </div>
                      </div>
                      {errors[index]?.paymentDue && (
                        <div className="error-message">
                          {errors[index]?.paymentDue}
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-end delete-btn">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => deleteMilestone(index, milestone?._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </form>
                </div>
              ))}

              <div className="d-grid mt-2 mt-md-4 mb-3 mb-sm-4 gap-3">
                <button
                  className="btn addnew-Milestone"
                  type="button"
                  onClick={addMilestone}
                >
                  + Add New Milestone
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-12">
          <div className="left-sec-content">
            <h2 className="text-center mb-4">Preview</h2>
            <div className="card-box">
              <div className="inner-card-box">
                <div className="title-content">
                  <h3>ServiceMVP website</h3>
                </div>
                <div className="time-details">
                  <h3>#on-time</h3>
                </div>
              </div>
              <div className="card-bottom">
                <div className="milestone-cover">
                  <h5>{formatDate(startDate)}</h5>
                  <div className="milestone-process"></div>
                  <h5>{formatDate(endDate)}</h5>
                </div>
              </div>
            </div>

            <div className="cardBox-content-item">
              <div className="milestone-edit">
                <h4>MILESTONES</h4>
              </div>
              {milestones.map(
                (milestone, index) =>
                  milestone.daysToComplete && (
                    <div key={index}>
                      <div className="design-item">
                        <p>{milestone.name}</p>
                      </div>
                      <div className="design-progress-item">
                        <ProgressBar
                          now={0}
                          // now={getProgressPercentage(milestone.daysToComplete)}
                        />
                        <span className="percentage">
                          0%
                          {/* {getProgressPercentage(milestone.daysToComplete)}% */}
                        </span>
                      </div>
                    </div>
                  )
              )}
              {milestones.map(
                (milestone, index) =>
                  milestone.name && (
                    <div
                      className={`skill-rols-list ${index == 0 ? "mt-2" : ""}`}
                      key={index}
                    >
                      <p>{milestone.name}</p>
                    </div>
                  )
              )}
            </div>

            <div className="mt-4 btn-group-milestone">
              <button
                className="btn-white"
                type="button"
                onClick={() => {
                  createProject(true);
                }}
                disabled={!isUpdateEnable}
              >
                {!loadingTemplate ? "Save as Template" : selectLoader(35)}
              </button>
              <button
                className="btn-black"
                type="button"
                onClick={() => {
                  createProject(false);
                }}
              >
                {!loading ? "Create Project" : selectLoader(35)}
              </button>
            </div>
            {selectedTemplateId && (
              <div className="backTobtn">
                <button
                  className="btn addnew-Milestone"
                  type="button"
                  onClick={backToTemplate}
                >
                  Back to Templates
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
