import Select from "react-select";
import "../assets/css/add-updated-modal.css";
import "../assets/css/sign-off-request-modal.css";
import "../assets/css/change-order-modal.css";
import "../assets/css/new-additional-modal.css";
// modal contents for the project overview tabs.

export const signOffModalContent = (
  milestoneOptions,
  statusOptions,
  signOffTimeOptions
) => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className="form-control select2"
              options={milestoneOptions}
              placeholder="Select Milestones"
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className="form-control select2"
              options={statusOptions}
              placeholder="Status"
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Write Request Description"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className="form-control select2"
              options={signOffTimeOptions}
              placeholder="Sign-off time"
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <a className="attech-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.97"
                  height="17.4"
                  viewBox="0 0 8.97 17.4"
                >
                  <path
                    id="Icon_ionic-ios-attach"
                    data-name="Icon ionic-ios-attach"
                    d="M18.24,7.263a.607.607,0,0,0-.606.606v7.549a3.131,3.131,0,0,1-.854,2.14,3.073,3.073,0,0,1-2.155.92H14.1a3.018,3.018,0,0,1-2.128-.955,3.118,3.118,0,0,1-.928-2.143V5.64a2.11,2.11,0,0,1,.672-1.534A2.31,2.31,0,0,1,13.3,3.473,2.161,2.161,0,0,1,15.487,5.64v9.094a1.227,1.227,0,0,1-1.126,1.25,1.193,1.193,0,0,1-1.1-1.25V9.546a.606.606,0,0,0-1.212,0v5.188a2.33,2.33,0,0,0,3.965,1.747,2.453,2.453,0,0,0,.68-1.747V5.64a3.394,3.394,0,0,0-3.405-3.39,3.408,3.408,0,0,0-2.415.99,3.35,3.35,0,0,0-1.006,2.4v9.739a4.34,4.34,0,0,0,1.266,3.029,4.2,4.2,0,0,0,2.955,1.239h.528A4.242,4.242,0,0,0,17.568,18.4,4.362,4.362,0,0,0,18.5,17.06a4.065,4.065,0,0,0,.346-1.635V7.869A.6.6,0,0,0,18.24,7.263Z"
                    transform="translate(-9.872 -2.25)"
                    fill="#656565"
                  />
                </svg>
                Attach Files
              </a>
              <a className="add-btn-text">
                + Add
              </a>
            </div>
            <div className="user-box-cover">
              <div className="username">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.813"
                  height="24.929"
                  viewBox="0 0 21.813 24.929"
                  className="file-icone"
                >
                  <path
                    id="Icon_metro-file-picture"
                    data-name="Icon metro-file-picture"
                    d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                    transform="translate(-4.499 -1.928)"
                    fill="#818181"
                  />
                </svg>
                <div className="username-content">
                  <p>hero banner.jpg</p>
                  <small>553KB</small>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.614"
                height="17.504"
                viewBox="0 0 13.614 17.504"
                className="delete-icon"
              >
                <path
                  id="Icon_material-delete"
                  data-name="Icon material-delete"
                  d="M8.472,20.059A1.951,1.951,0,0,0,10.417,22H18.2a1.951,1.951,0,0,0,1.945-1.945V8.39H8.472ZM21.114,5.472h-3.4L16.738,4.5H11.876l-.972.972H7.5V7.417H21.114Z"
                  transform="translate(-7.5 -4.5)"
                  fill="#818181"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* Additional content remains the same */}
      <button className="btn-black">Submit</button>
    </>
  );
};

export const changeOrderModalContent = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="text"
              placeholder="Request Title"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Write Request Description"
              className="form-control"
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <a href="" className="attech-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.97"
                  height="17.4"
                  viewBox="0 0 8.97 17.4"
                >
                  <path
                    id="Icon_ionic-ios-attach"
                    data-name="Icon ionic-ios-attach"
                    d="M18.24,7.263a.607.607,0,0,0-.606.606v7.549a3.131,3.131,0,0,1-.854,2.14,3.073,3.073,0,0,1-2.155.92H14.1a3.018,3.018,0,0,1-2.128-.955,3.118,3.118,0,0,1-.928-2.143V5.64a2.11,2.11,0,0,1,.672-1.534A2.31,2.31,0,0,1,13.3,3.473,2.161,2.161,0,0,1,15.487,5.64v9.094a1.227,1.227,0,0,1-1.126,1.25,1.193,1.193,0,0,1-1.1-1.25V9.546a.606.606,0,0,0-1.212,0v5.188a2.33,2.33,0,0,0,3.965,1.747,2.453,2.453,0,0,0,.68-1.747V5.64a3.394,3.394,0,0,0-3.405-3.39,3.408,3.408,0,0,0-2.415.99,3.35,3.35,0,0,0-1.006,2.4v9.739a4.34,4.34,0,0,0,1.266,3.029,4.2,4.2,0,0,0,2.955,1.239h.528A4.242,4.242,0,0,0,17.568,18.4,4.362,4.362,0,0,0,18.5,17.06a4.065,4.065,0,0,0,.346-1.635V7.869A.6.6,0,0,0,18.24,7.263Z"
                    transform="translate(-9.872 -2.25)"
                    fill="#656565"
                  />
                </svg>
                Attach Files
              </a>
              <a href="" className="add-btn-text">
                + Add
              </a>
            </div>
            <div className="user-box-cover">
              <div className="username">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.813"
                  height="24.929"
                  viewBox="0 0 21.813 24.929"
                  className="file-icone"
                >
                  <path
                    id="Icon_metro-file-picture"
                    data-name="Icon metro-file-picture"
                    d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                    transform="translate(-4.499 -1.928)"
                    fill="#818181"
                  />
                </svg>
                <div className="username-content">
                  <p>hero banner.jpg</p>
                  <small>553KB</small>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.614"
                height="17.504"
                viewBox="0 0 13.614 17.504"
                className="delete-icon"
              >
                <path
                  id="Icon_material-delete"
                  data-name="Icon material-delete"
                  d="M8.472,20.059A1.951,1.951,0,0,0,10.417,22H18.2a1.951,1.951,0,0,0,1.945-1.945V8.39H8.472ZM21.114,5.472h-3.4L16.738,4.5H11.876l-.972.972H7.5V7.417H21.114Z"
                  transform="translate(-7.5 -4.5)"
                  fill="#818181"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <button className="btn-black">Submit</button>
    </>
  );
};

export const requestModalContent = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="text"
              placeholder="Request Title"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Write Request Description"
              className="form-control"
            ></textarea>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <a href="" className="attech-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.97"
                  height="17.4"
                  viewBox="0 0 8.97 17.4"
                >
                  <path
                    id="Icon_ionic-ios-attach"
                    data-name="Icon ionic-ios-attach"
                    d="M18.24,7.263a.607.607,0,0,0-.606.606v7.549a3.131,3.131,0,0,1-.854,2.14,3.073,3.073,0,0,1-2.155.92H14.1a3.018,3.018,0,0,1-2.128-.955,3.118,3.118,0,0,1-.928-2.143V5.64a2.11,2.11,0,0,1,.672-1.534A2.31,2.31,0,0,1,13.3,3.473,2.161,2.161,0,0,1,15.487,5.64v9.094a1.227,1.227,0,0,1-1.126,1.25,1.193,1.193,0,0,1-1.1-1.25V9.546a.606.606,0,0,0-1.212,0v5.188a2.33,2.33,0,0,0,3.965,1.747,2.453,2.453,0,0,0,.68-1.747V5.64a3.394,3.394,0,0,0-3.405-3.39,3.408,3.408,0,0,0-2.415.99,3.35,3.35,0,0,0-1.006,2.4v9.739a4.34,4.34,0,0,0,1.266,3.029,4.2,4.2,0,0,0,2.955,1.239h.528A4.242,4.242,0,0,0,17.568,18.4,4.362,4.362,0,0,0,18.5,17.06a4.065,4.065,0,0,0,.346-1.635V7.869A.6.6,0,0,0,18.24,7.263Z"
                    transform="translate(-9.872 -2.25)"
                    fill="#656565"
                  />
                </svg>
                Attach Files
              </a>
              <a href="" className="add-btn-text">
                + Add
              </a>
            </div>
            <div className="user-box-cover">
              <div className="username">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.813"
                  height="24.929"
                  viewBox="0 0 21.813 24.929"
                  className="file-icone"
                >
                  <path
                    id="Icon_metro-file-picture"
                    data-name="Icon metro-file-picture"
                    d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                    transform="translate(-4.499 -1.928)"
                    fill="#818181"
                  />
                </svg>
                <div className="username-content">
                  <p>hero banner.jpg</p>
                  <small>553KB</small>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.614"
                height="17.504"
                viewBox="0 0 13.614 17.504"
                className="delete-icon"
              >
                <path
                  id="Icon_material-delete"
                  data-name="Icon material-delete"
                  d="M8.472,20.059A1.951,1.951,0,0,0,10.417,22H18.2a1.951,1.951,0,0,0,1.945-1.945V8.39H8.472ZM21.114,5.472h-3.4L16.738,4.5H11.876l-.972.972H7.5V7.417H21.114Z"
                  transform="translate(-7.5 -4.5)"
                  fill="#818181"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <button className="btn-black">Submit</button>
    </>
  );
};
export const requestFeedbackModalContent = (milestoneOptions) => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className="form-control select2"
              options={milestoneOptions}
              placeholder="Select Milestones"
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Write Request Description"
              className="form-control"
            />
          </div>
        </div>
      </div>

      <button className="btn-black">Submit</button>
    </>
  );
};

export const addQuoteModalContent = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <div className="new-addition-list">
              <a href="#" className="active">
                <p>Option</p>
                <span>1</span>
              </a>
              <a href="#">
                <p>Option</p>
                <span>2</span>
              </a>
              <a href="#">
                <p>&nbsp;</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  className="add-icon"
                >
                  <g
                    id="Group_2687"
                    data-name="Group 2687"
                    transform="translate(-1091 -135)"
                  >
                    <g
                      id="Rectangle_747"
                      data-name="Rectangle 747"
                      transform="translate(1091 135)"
                      fill="#e5ede8"
                      stroke="#b1b2b2"
                      strokeWidth="2"
                      stroke-dasharray="5"
                    >
                      <rect width="60" height="60" rx="30" stroke="none" />
                      <rect
                        x="1"
                        y="1"
                        width="58"
                        height="58"
                        rx="29"
                        fill="none"
                      />
                    </g>
                    <text
                      id="_"
                      data-name="+"
                      transform="translate(1121 173)"
                      fill="#818181"
                      font-size="22"
                      font-family="Roboto-Regular, Roboto"
                    >
                      <tspan x="-6" y="0">
                        +
                      </tspan>
                    </text>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="text"
              placeholder="Request Title"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Write Request Description"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="text"
              placeholder="Additional Cost"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="date-cover">
              <a href="" className="start-date">
                + Due date
              </a>
              <a href="" className="due-date-text">
                + Due date
              </a>
            </div>
          </div>
        </div>
      </div>

      <button className="btn-black">Submit</button>
    </>
  );
};
