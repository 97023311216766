import React from "react";
import { selectLoader } from "../../../utils/Helpers";

const UpdatedScope = ({ scopeData,loading }) => {

  // console.log(scopeData);
  
  return (
    <div className="accordion" id="faq">
      {loading ? <div className="nodata-loader">{selectLoader(65)}</div>
      : scopeData?.map((item, index) => (
        <div className="card_cover" key={item._id}>
          <div className="card-header" id={`faqhead${index}`}>
            <a
              className="btn btn-header-link"
              data-bs-toggle="collapse"  // Update for Bootstrap 5
              data-bs-target={`#faq${index}`}     // Unique ID for each section
              aria-expanded={false}         // Expand the first item by default
              aria-controls={`faq${index}`}
            >
              {item.title}
            </a>
          </div>
          <div
            id={`faq${index}`}
            className={`collapse ${index === 0 ? "" : ""}`}  // Show the first item by default
            aria-labelledby={`faqhead${index}`}
            data-bs-parent="#faq"
          >
            <div className="card-body">
              {item.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpdatedScope;
