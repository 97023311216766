import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import APIServices from "../services/APIServices";
import { selectLoader } from "../utils/Helpers";

const OrganizationSelect = ({
  formValues,
  handleChange,
  openModal,
  errors,
  organizationsList,
  orgloading
}) => {
  const [organizations, setOrganizations] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(organizationsList){
      setOrganizations(organizationsList)

    }
  }, [organizationsList]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "5px",
      borderRadius: "4px",
    }),
  };

  // Custom MenuList to include the button inside the dropdown
  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {orgloading ? (
          <div className="d-flex justify-content-center py-2">
            {selectLoader(35)}
          </div>
        ) : (
          <>
            {/* Render the options normally when not loading */}
            {props.children}
          </>
        )}

        {/* Add the button at the bottom of the dropdown */}
        <div className="p-2">
          <button
            type="button"
            className="btn btn-outline-secondary w-100"
            onClick={(e) => {
              e.stopPropagation();
              openModal(); // Trigger modal when button is clicked
            }}
          >
            + Add New Organization
          </button>
        </div>
      </components.MenuList>
    );
  };

  const handleSelectChange = (selectedOption) => {
    handleChange({
      target: {
        name: "organizationId",
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  // Find the selected organization object by its ID
  const selectedOrganization = organizations.find(
    (org) => org.value === formValues.organizationId
  );

  return (
    <div className="input-group">
      <div className="organization-select">
        <Select
          value={selectedOrganization || null}
          onChange={handleSelectChange}
          options={organizations}
          className={errors.organizationId ? "form-invalid" : ""}
          styles={customStyles}
          placeholder="Organization"
          isClearable
          components={{ MenuList: CustomMenuList }} // Custom dropdown component
        />
        {errors.organizationId && (
          <div className="error-message">{errors.organizationId}</div>
        )}
      </div>
    </div>
  );
};

export default OrganizationSelect;
