import React, { useEffect, useState } from "react";
import deleteBtn from "../../../assets/img/delete.svg";
import attechFile from "../../../assets/img/ios-attach.svg";
import filePic from "../../../assets/img/file-picture.svg";
import { MAX_FILE_SIZE, selectLoader } from "../../../utils/Helpers";
import APIServices from "../../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";

const ScopeOfWork = ({ projectDetails, setCurrentTab, setCompletedTabs }) => {
  const [projectId, setProjectId] = useState("");
  const [scopeId, setScopeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [showSkip, setShowSkip] = useState(true);
  const [sections, setSections] = useState([
    {
      id: "projectOverview",
      label: "Project Overview",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" }, // Added error state
    },
    {
      id: "projectDeliverables",
      label: "Project Deliverables",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    },
    {
      id: "projectWebPages",
      label: "The number of Web Pages",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    },
    {
      id: "projectDesignRevisions",
      label: "The number of Design Revisions",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    },
    {
      id: "projectTimeline",
      label: "Project Timeline",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    },
    {
      id: "projectBudget",
      label: "Project Budget",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    },
    {
      id: "paymentTerms",
      label: "Payment Terms",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    },
  ]);

  const [files, setFiles] = useState([]); // State to handle files

  useEffect(() => {
    if (projectDetails) {
      setProjectId(projectDetails._id);
    }
  }, [projectDetails]);

  // Handle text change for content
  const handleTextChange = (e, id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? {
            ...section,
            content: e.target.value,
            errors: { ...section.errors, content: "" },
          }
        : section
    );
    setSections(updatedSections);
  };

  // Handle text change for title (label)
  const handleTitleChange = (e, id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? {
            ...section,
            label: e.target.value,
            errors: { ...section.errors, label: "" },
          }
        : section
    );
    setSections(updatedSections);
  };

  // Toggle edit mode
  const toggleEdit = (id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? { ...section, isEditable: !section.isEditable }
        : section
    );
    setSections(updatedSections);
  };

  // Delete section
  const handleDelete = (id) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
  };

  // Add new section
  const addNewSection = () => {
    const newSection = {
      id: `newSection${sections.length + 1}`,
      label: `New Section ${sections.length + 1}`,
      content: "",
      isEditable: true,
      errors: { label: "", content: "" }, // Initialize error state
    };
    setSections([...sections, newSection]);
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the file size exceeds the maximum allowed size (20MB)
      if (file.size > MAX_FILE_SIZE) {
        appToast("The file size should not exceed 20MB.", TOAST_TYPE.ERROR); // Show an alert or handle error
        return; // Prevent further processing of this file
      }
      setFiles([...files, ...e.target.files]);
    }
  };

  // Remove file
  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  // Validate inputs
  const validateInputs = () => {
    let isValid = true;
    const updatedSections = sections.map((section) => {
      let labelError = "";
      let contentError = "";

      if (!section.label.trim()) {
        labelError = "Title is required.";
        isValid = false;
      }

      if (!section.content.trim()) {
        contentError = "Details are required.";
        isValid = false;
      }

      return {
        ...section,
        errors: { label: labelError, content: contentError },
      };
    });

    setSections(updatedSections);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (isSkip = false) => {
    let contentType = {
      "Content-Type": "multipart/form-data",
    };

    if (!isSkip) {
      if (!validateInputs()) {
        // If validation fails, stop the form submission
        return;
      }
    }
    setLoading(true);
    // Create FormData object
    let formData = new FormData();

    // Append projectId
    formData.append("projectId", projectId);

    // Create scope array from sections and append to formData
    const scope = isSkip
      ? []
      : sections.map((section) => ({
          title: section.label,
          description: section.content,
        }));
    formData.append("scope", JSON.stringify(scope));

    // Append files to formData
    files.forEach((file, index) => {
      formData.append(`attachments`, file);
    });
    // TODO: Add your API call to submit the formData
    try {
      const response = isEditing
        ? await APIServices.put(
            `/project-scope/${scopeId}`,
            formData,
            contentType
          )
        : await APIServices.post("/project-scope", formData, contentType);

      if (response.projectId) {
        setScopeId(response._id);
        setCurrentTab(4);
        setCompletedTabs((prev) => ({
          ...prev,
          [4]: true, // Enable the next tab
        }));
        setShowSkip(false);
        setEditing(true);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="scope-of-work mt-5">
      <form>
        {sections.map((section, index) => (
          <div className="mb-4" key={section.id}>
            <label
              htmlFor={section.id}
              className={`form-label bg-white d-flex  justify-content-between br-10 text-area-label ${
                section.errors.label ? "form-invalid" : ""
              }`}
            >
              {/* Editable title input field */}
              {section.isEditable ? (
                <input
                  value={section.label}
                  onChange={(e) => handleTitleChange(e, section.id)}
                  className="text-left w-100 label-text-area border-none"
                />
              ) : (
                <span className={`label-text-area w-100 text-left`}>
                  {section.label}
                </span>
              )}

              <a
                className="edit text-right"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleEdit(section.id);
                }}
              >
                {section.isEditable ? (
                  <i className="fas fa-save"></i>
                ) : (
                  <i className="fas fa-pen"></i>
                )}
              </a>
            </label>
            {/* Error message for title */}
            {section.errors.label && (
              <p className="text-danger">{section.errors.label}</p>
            )}

            {/* Section content textarea */}
            <textarea
              className={`form-control  br-10 mb-2 p-3 ${
                !section.isEditable ? "input-desable" : ""
              } ${section.errors.content ? "form-invalid" : ""}`}
              id={section.id}
              placeholder="Write Details"
              rows="3"
              value={section.content}
              onChange={(e) => handleTextChange(e, section.id)}
              readOnly={!section.isEditable}
            />
            {/* Error message for content */}
            {section.errors.content && (
              <p className="text-danger">{section.errors.content}</p>
            )}

            {index != 0 && (
              <div className="delete-btn">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => handleDelete(section.id)}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
        <div className="mb-4">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <label className="attech-btn">
                <img src={attechFile} alt="comp1" className="attechFile" />
                Attach Files
              </label>

              <label className="add-btn-text cursor_pointer">
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                + Add
              </label>
            </div>
            {files.map((file, index) => (
              <div className="user-box-cover" key={index}>
                <div className="username">
                  <img src={filePic} alt="comp1" className="filePic" />
                  <div className="username-content">
                    <p>{file.name}</p>
                    <small>{(file.size / 1024).toFixed(2)} KB</small>
                  </div>
                </div>
                <img
                  src={deleteBtn}
                  alt="comp1"
                  className="deleteBtn"
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="d-grid mt-5 mb-3 gap-3">
          <button
            className="add-new-section"
            type="button"
            onClick={addNewSection}
          >
            + Add new section
          </button>
          <button
            className="btn-black"
            type="button"
            onClick={() => {
              handleSubmit(false);
            }}
          >
            {!loading ? (isEditing ? "Update" : "Next") : selectLoader(35)}
          </button>
        </div>
      </form>
      {/* {showSkip && (
        <div className="py-4">
          <a
            onClick={() => {
              handleSubmit(true);
            }}
            className="link link-color cursor_pointer"
          >
            Skip
          </a>
        </div>
      )} */}
    </div>
  );
};

export default ScopeOfWork;
