import React, { Suspense } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import { useAuth } from "./hooks/useAuth";
import Login from "./pages/auth/Login";
import AuthLayout from "./layouts/AuthLayout";
import ComingSoon from "./pages/ComingSoon";
import VerificationStatus from "./pages/auth/VerificationStatus";
import Verification from "./pages/auth/Verification";
import { selectLoader } from "./utils/Helpers";
import ProfileCompletion from "./pages/auth/ProfileCompletion";
import Home from "./pages/projects/Home";
import ClientDirectory from "./pages/projects/ClientDirectory";
import MyTeam from "./pages/projects/MyTeam";
import ProjectOverview from "./pages/projects/ProjectOverview";
import AcceptInvite from "./pages/auth/AcceptInvite";
import OrganizationUsers from "./pages/projects/OrganizationUsers";
import UpdatedOverview from "./pages/projects/UpdatedOverview";

const RequireAuth = ({ children }) => {
  const { token } = useAuth(AuthContext);
  if (!token) {
    return <Navigate to={"/login"} />;
  }
  return (
    <>
      <Suspense fallback={selectLoader(50)}>
        <Outlet />
      </Suspense>
    </>
  );
};

// New protected route for isUser1
const RequireUser1 = ({ children }) => {
  const { isUser1 } = useAuth(AuthContext);

  if (!isUser1) {
    return <Navigate to={"/projects"} />;
  }
  return (
    <Suspense fallback={selectLoader(50)}>
      <Outlet />
    </Suspense>
  );
};

const RoutesComponent = () => {
  const { token } = useAuth(AuthContext);
  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route
            path="/"
            element={
              token ? <Navigate to="/projects" /> : <Navigate to="/login" />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/complete-profile" element={<ProfileCompletion />} />
          <Route path="/accept-invitation/:token" element={<AcceptInvite />} />
          {/* <Route path="/verification-status" element={<VerificationStatus />} /> */}
        </Route>
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/" element={<RequireAuth />}>
          <Route path="/projects" element={<Home />} />

          <Route path="/myteam" element={<MyTeam />} />
          <Route path="/overview" element={<UpdatedOverview />} />
          <Route element={<RequireUser1 />}>
            <Route path="/create-project" element={<ProjectOverview />} />
            <Route path="/client-directory" element={<ClientDirectory />} />
            <Route
              path="/client-directory/Client"
              element={<OrganizationUsers />}
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
