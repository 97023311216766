import React, { useState } from "react";
import RequestListTab from "./requestscreens/RequestListTab";
import RequestDetails from "./requestscreens/RequestDetails";
const RequestTab = () => {
  const [activeTab, setActiveTab] = useState("requestList");
  
  return (
    <div>
      {activeTab === "requestList" && <RequestListTab setParentTab={setActiveTab} />}
      {activeTab === "requestDetails" && <RequestDetails />}
    </div>
  );
};

export default RequestTab;
