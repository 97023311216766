import React, { useEffect, useState } from "react";
import "../../assets/css/login.css";
import loginLogo from "../../assets/img/login-logo2.svg";
import logoHover from "../../assets/img/login-logohover.svg";
import { loginStrings } from "./strings/authStrings";
import PhoneNumberInput from "../../components/PhoneInput";
import {
  getPlainPhoneNumber,
  manageRememberMe,
  selectLoader,
  userType,
  validateEmail,
  validatePhoneNumber,
} from "../../utils/Helpers";
import APIServices from "../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../hooks/useStore";
import Cookies from "universal-cookie"; // Import cookies

const cookies = new Cookies(); // Instantiate cookies
const Login = () => {
  const navigate = useNavigate();
  const [store, setStore] = useStore();
  const [loading, setLoading] = useState(false);
  const [verificationType, setVerificationType] = useState("email");
  const [email, setEmail] = useState("");
  const [emailError, setEmailErrors] = useState("");
  const [phoneError, setPhoneErrors] = useState("");
  const [rememberDevice, setRememberDevice] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState({
    countryDialCode: "",
    phoneNumber: "",
    countryCode: "US",
  });

  // UseEffect to check if cookies exist and pre-fill the form based on verificationType
  useEffect(() => {
    const rememberDevice = cookies.get("rememberDevice");

    if (rememberDevice) {
      const savedVerificationType = cookies.get("verificationType");

      if (savedVerificationType) {
        setVerificationType(savedVerificationType);
        if (savedVerificationType === "email") {
          const savedEmail = cookies.get("email");
          if (savedEmail) setEmail(savedEmail);
        } else if (savedVerificationType === "phone") {
          const savedPhoneDetails = cookies.get("phoneDetails");
          if (savedPhoneDetails) setPhoneDetails(savedPhoneDetails);
        }
      }
    }

    const savedRememberDevice = cookies.get("rememberDevice") === "true";
    setRememberDevice(savedRememberDevice);
  }, []);

  const handleVerificationTypeChange = (event) => {
    setVerificationType(event.target.id === "rdo1" ? "email" : "phone");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailErrors("");
  };

  const handlePhoneChange = (val, countryDialCode, countryCode) => {
    setPhoneDetails({
      ...phoneDetails,
      phoneNumber: val,
      countryDialCode: `${countryDialCode}`,
      countryCode: countryCode,
    });
    setPhoneErrors("");
  };

  const validateForm = () => {
    if (verificationType === "email") {
      return validateEmail(email, setEmailErrors);
    } else if (verificationType === "phone") {
      return validatePhoneNumber(getPlainPhoneNumber(phoneDetails.phoneNumber), setPhoneErrors);
    }
    return false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;
    let plainNumber = getPlainPhoneNumber(phoneDetails.phoneNumber);
    let phoneNumber = `${phoneDetails.countryDialCode}${plainNumber}`;
    const url = "/register-login";
    const payload =
      verificationType === "email"
        ? { email, userRoleName: userType }
        : {
            countryDialCode: phoneDetails.countryDialCode,
            phoneNumber: plainNumber,
            userRoleName: userType,
          };
    const isEmail = verificationType === "email";

    setLoading(true);

    try {
      const response = await APIServices.post(url, payload);
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        setStore({
          countryDialCode: phoneDetails.countryDialCode,
          phoneNumber: plainNumber,
          userEmail: email,
          verificationType: verificationType,
        });

        // Manage cookies based on verification type and remember option
        manageRememberMe(verificationType, email, phoneDetails, rememberDevice);
        setStore({ UserDetails: {}, countryName: "" });
        navigate("/verification", {
          state: { isEmail: isEmail, diableFields: false },
        });
      } else {
        appToast(
          response.data.message || loginStrings.errorOccurred,
          TOAST_TYPE.ERROR
        );
      }
    } catch (err) {
      console.error(`${loginStrings.errorWhile} ${verificationType}`, err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form_right login_page">
      <div className="login-box">
        <div className="login-logo">
          {/* <!-- <a href="index.html">
          <img src="dist/img/login-logo.png">
        </a> --> */}
          <a className="happyLogo-cover" href="index.html">
            <img
              src={loginLogo}
              alt={loginStrings.happyEnding}
              className="happy-logo"
            />
            <img
              src={logoHover}
              alt={loginStrings.happyEnding}
              className="happy-logo2"
            />
          </a>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">{loginStrings.happyEnding}</p>
            <p className="login-box-smalltext">
              {loginStrings.loginToContinue}
            </p>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="rediobtn-group-box mb-3">
                    <div className="rediobtnbox">
                      <input
                        type="radio"
                        id="rdo1"
                        className="radio-input"
                        name="radio-group"
                        checked={verificationType === "email"}
                        onChange={handleVerificationTypeChange}
                      />
                      <label htmlFor="rdo1" className="radio-label">
                        <span className="radio-border"></span>{" "}
                        {loginStrings.email}
                      </label>
                    </div>
                    <div className="rediobtnbox">
                      <input
                        type="radio"
                        id="rdo2"
                        className="radio-input"
                        name="radio-group"
                        checked={verificationType === "phone"}
                        onChange={handleVerificationTypeChange}
                      />
                      <label htmlFor="rdo2" className="radio-label">
                        <span className="radio-border"></span>{" "}
                        {loginStrings.phone}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`row form-floating-tab1 ${
                  verificationType === "email" ? "on" : ""
                }`}
              >
                <div className="col-lg-12">
                  <div className="input-group mb-3">
                    <input
                      value={email}
                      type="text"
                      className={`form-control ${
                        emailError ? "form-invalid" : ""
                      }`}
                      placeholder={loginStrings.emailAddressPlaceholder}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <span className="error-message">{emailError}</span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`row form-floating-tab1 ${
                  verificationType === "phone" ? "on" : ""
                }`}
              >
                <div className="col-lg-12">
                  <div className="input-group mb-3 phone-number-group">
                    <div className="mb-10" style={{ width: "100%" }}>
                      <PhoneNumberInput
                        value={phoneDetails.phoneNumber}
                        countryDialCode={phoneDetails.countryDialCode}
                        countryName={phoneDetails.countryCode}
                        onPhoneChange={(val) =>
                          handlePhoneChange(val, phoneDetails.countryDialCode)
                        }
                        onCountryChange={(country) => {
                          setPhoneDetails({
                            ...phoneDetails,
                            countryDialCode: country.dialCode,
                            countryCode: country.value,
                          });
                          handlePhoneChange(
                            phoneDetails.phoneNumber,
                            country.dialCode,
                            country.value
                          );
                        }}
                        errors={phoneError}
                      />
                      {phoneError && (
                        <span className="error-message">{phoneError}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-black btn-block">
                    {!loading ? loginStrings.continueButton : selectLoader(35)}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-group privacy-policy">
                    <label className="form-check">
                      <input
                        type="checkbox"
                        name="rememberAccount"
                        checked={rememberDevice}
                        onChange={() => {
                          setRememberDevice(!rememberDevice);
                        }}
                      />
                      <span className="checkmark"></span>
                      {/* Remember this device. */}
                    </label>
                    <p className="privacy-policy-text">Remember this device.</p>
                  </div>
                </div>
              </div>
            </form>

            {/* <div className="or-login">
              or Log-in with
            </div>
            <div className="social-link">
              <a href="#">
                <img
                  src={linkedinIcon}
                />

              </a>
              <a href="#">
                <img
                  src={facebookIcon}
                />
              </a>
              <a href="#">
                <img
                  src={googleIcon}
                />

              </a>

            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
