import React, { useState, useEffect } from "react";
import { selectLoader } from "../../../utils/Helpers";
import search from "../../../assets/img/search.svg";
import APIServices from "../../../services/APIServices";
import { useAuth } from "../../../contexts/AuthContext";

const SelectTemplate = ({ setTemplateId, setMilestoneProgress, companyId }) => {
  const { user, inviterId } = useAuth();
  // State to control which template section is active
  const [activeTab, setActiveTab] = useState("custom");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [basicTemplates, setBasicTemplates] = useState([]);
  const [customTemplates, setCustomTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  // Function to generate random colors
  const getRandomColor = () => {
    const colors = [
      "yallow-bg-color",
      "overview-purple-bg",
      "overview-background-bg",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  useEffect(() => {
    if (companyId) {
      fetchTemplates(companyId, searchTerm);
    }
  }, [companyId, searchTerm]);

  const fetchTemplates = async (companyId, searchText = "") => {
    try {
      setLoading(true);
      const response = searchText
        ? await APIServices.get(
            `/template/search/${companyId}?templateName=${searchText}`
          )
        : await APIServices.get(`/template/company/${companyId}`);
      if (response.templates) {
        // Assuming all templates are fetched in a single array and you need to split them
        const templates = response.templates;

        // // Set basicTemplates (for this example, assuming templates with less than 4 milestones are basic)
        // const fetchedBasicTemplates = templates.filter(
        //   (template) => template.milestoneCount < 4
        // );
        // setBasicTemplates(fetchedBasicTemplates);

        // Set customTemplates (assuming templates with 4 or more milestones are custom)
        const fetchedCustomTemplates = templates.filter(
          (template) => template.milestoneCount >= 4
        );
        setCustomTemplates(templates);
      } else {
        console.error("Failed to fetch templates.");
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setLoading(false);
    }
  };

  // Update filtered templates whenever the search term or active tab changes
  useEffect(() => {
    const templates = activeTab === "basic" ? basicTemplates : customTemplates;
    // const filtered = templates.filter((template) =>
    //   template.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    setFilteredTemplates(templates);
  }, [activeTab, basicTemplates, customTemplates]);

  const handleTemplateSelect = (templateId) => {
    // console.log("Selected template:", template);
    setTemplateId(templateId);
    setMilestoneProgress("add");
    // Handle template selection logic here, like sending to a parent component
  };

  return (
    <div className="choose-templates mt-5">
      <h1 className="text-center mb-4">Choose a template</h1>

      {/* Template Tabs */}
      <div className="d-flex justify-content-center">
        <div className="bg-white br-10 p-2  btn-tab-group">
          <button
            className={`btn btn-custom ${
              activeTab === "custom" ? "btn-dark" : "btn-light"
            }`}
            onClick={() => setActiveTab("custom")}
          >
            My Templates
          </button>
          <button
            className={`btn btn-custom  ${
              activeTab === "basic" ? "btn-dark" : "btn-light"
            }`}
            onClick={() => setActiveTab("basic")}
          >
            Basic Templates
          </button>
        </div>
      </div>

      {/* Search Input */}
      <div className="input-group mt-4">
        <button className="input-group-text bg-white border-0 fa-search-btn">
          <img src={search} alt="comp1" className="search-img" />
        </button>
        <input
          type="text"
          className="form-control ps-0"
          placeholder="Search"
          aria-label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Loading Spinner */}
      {loading ? (
        selectLoader(50)
      ) : (
        <>
          {/* Display Templates */}
          <div className="row mt-4 templates-row">
            {filteredTemplates.length > 0 ? (
              filteredTemplates.map((template) => (
                <div key={template._id} className="col-md-6">
                  <button
                    className={`btn btn-lg w-100 mb-3 position-relative text-left text-white hover ${getRandomColor()}`}
                    onClick={() => handleTemplateSelect(template._id)}
                    aria-label={`Select ${template.name}`}
                  >
                    {template.name}
                    <p className="subtitle text-white">
                      {template.milestoneCount} Milestones
                    </p>
                    <span
                      className="position-absolute translate-middle-y"
                      style={{ top: "50%", right: "20px" }}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </button>
                </div>
              ))
            ) : (
              <div className={`text-center mt-3 ${activeTab === "basic" ? "milestones-comming-soon" : ""}`}>
                <p>
                  {activeTab === "basic"
                    ? "Coming soon..."
                    : "No templates found."}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectTemplate;
