import Cookies from "universal-cookie";
import { Oval } from "react-loader-spinner";
import { countries } from "country-data";
import APIServices from "../services/APIServices";
import appToast, { TOAST_TYPE } from "./AppToast";
import { ProfileCompletionStrings } from "../pages/auth/strings/authStrings";
import moment from "moment-timezone";

export const PAGE_LIMIT = 10;

const cookies = new Cookies();

export const userType = "owner";

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

export const getCookies = (name) => {
  return cookies.get(name);
};

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  //Application somehow unable to set cookie so storing token in sessionStorage and reading from same
  //sessionStorage.setItem(name,value)
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  try {
    if (name === "authToken")
      cookies.set(name, value, { expires: expire, path: "/", secure: true });
    else cookies.set(name, value, { expires: expire, path: "/" });
  } catch (err) {
    console.log(`Error setting cookie ${name} - value is ${value} --> ${err}`);
  }
};

// To fetch the user details from the api.
export const getUserDetails = async (token) => {
  let userRes = await APIServices.get(`/user/detail?token=${token}`);

  if (userRes.data) {
    return userRes.data;
  }

  return {};
};
//signup form default values
export const formFields = {
  isTotorialVisited: true,
  fullName: "",
  email: "",
  phoneNumber: "",
  countryDialCode: "+1",
  companyName: "",
  countryCode: "US",
  profilePic: null,
};

//function to check and validate the password strength
export const checkPasswordStrength = (password) => {
  let error = "";
  let strengthLevel = 0;

  // Check for minimum length (e.g., at least 8 characters)
  if (password.length < 8) {
    return {
      strengthLevel: 0,
      error: "Password must be at least 8 characters long.",
    };
  }

  strengthLevel++;

  // Check for at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    return {
      strengthLevel: 1,
      error: "Password must contain at least one uppercase letter.",
    };
  }

  strengthLevel++;

  // Check for at least one number
  if (!/[0-9]/.test(password)) {
    return {
      strengthLevel: 2,
      error: "Password must contain at least one number.",
    };
  }

  strengthLevel++;

  // Check for at least one special character
  if (!/[^A-Za-z0-9]/.test(password)) {
    return {
      strengthLevel: 3,
      error: "Password must contain at least one special character.",
    };
  }

  strengthLevel++; // All criteria met

  // Return the strength level and no error
  return {
    strengthLevel,
    error: "",
  };
};

//function to validate the form for registration page
export const validateForm = (formValues, validateCompany = false) => {
  let tempErrors = {};
  if (!formValues.fullName)
    tempErrors.fullName = "Please enter your full name.";
  if (!formValues.email) {
    tempErrors.email = "Please enter a valid email address.";
  } else {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formValues.email)) {
      tempErrors.email = "Please enter a valid email address.";
    }
  }
  if (!formValues.companyName && validateCompany) {
    tempErrors.companyName = "Please enter your Company name.";
  }
  if (
    !getPlainPhoneNumber(formValues.phoneNumber) ||
    getPlainPhoneNumber(formValues.phoneNumber).length < 10
  )
    tempErrors.phone = "Not enough digits for a phone number.";
  return tempErrors;
};

// Common loader for the button loading.
export const selectLoader = (size = 20) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
    }}
  >
    <Oval
      height={size}
      width={size}
      color="#ffff"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  </div>
);

// Countries options and dialcode data list for phone number input
export const countryOptions = countries.all
  .filter((country) => country.countryCallingCodes.length > 0)
  .map((country) => ({
    value: country.alpha2,
    label: `${country.name} (${country.countryCallingCodes[0]})`,
    dialCode: country.countryCallingCodes[0],
    flag: `flag-icon flag-icon-${country.alpha2.toLowerCase()}`,
    name: country.name,
  }));

//Validate the email
export const validateEmail = (email, stateUpdate) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email || !emailPattern.test(email)) {
    if (stateUpdate) {
      stateUpdate("Please enter a valid email address.");
    }
    return false;
  }
  return true;
};

//validate phone number for login page
export const validatePhoneNumber = (phoneNumber, setPhoneErrors) => {
  const isValid = phoneNumber && phoneNumber.length >= 10;
  if (!isValid) {
    if (setPhoneErrors) {
      setPhoneErrors("Not enough digits for a phone number.");
    }
  }
  return isValid;
};

//manages the remember me functionalities on the login page
export const manageRememberMe = (
  verificationType,
  email,
  phoneDetails,
  rememberDevice,
  country
) => {
  if (verificationType === "email") {
    cookies.set("userEmail", email, { path: "/", maxAge: 7 * 24 * 60 * 60 });
  } else if (verificationType === "phone") {
    cookies.set("userPhone", phoneDetails, {
      path: "/",
      maxAge: 7 * 24 * 60 * 60,
    });
    cookies.set("country", country, {
      path: "/",
      maxAge: 7 * 24 * 60 * 60,
    });
  }
  if (rememberDevice) {
    cookies.set("verificationType", verificationType, {
      path: "/",
      maxAge: 7 * 24 * 60 * 60,
    });

    if (verificationType === "email") {
      cookies.set("email", email, { path: "/", maxAge: 7 * 24 * 60 * 60 });
      cookies.remove("phoneDetails"); // Remove phone details if previously stored
      cookies.remove("country");
    } else if (verificationType === "phone") {
      cookies.set("phoneDetails", phoneDetails, {
        path: "/",
        maxAge: 7 * 24 * 60 * 60,
      });
      cookies.set("country", country, {
        path: "/",
        maxAge: 7 * 24 * 60 * 60,
      });
      cookies.remove("email"); // Remove email if previously stored
    }

    cookies.set("rememberDevice", rememberDevice, {
      path: "/",
      maxAge: 7 * 24 * 60 * 60,
    });
  } else {
    // Clear all related cookies if "Remember this device" is not selected
    cookies.remove("email");
    cookies.remove("phoneDetails");
    cookies.remove("verificationType");
    cookies.remove("rememberDevice");
    cookies.remove("country");
  }
  cookies.set("userType", verificationType, {
    path: "/",
    maxAge: 7 * 24 * 60 * 60,
  });
};

// Function to get the plain numeric phone number for API
export const getPlainPhoneNumber = (phoneNumber) => {
  let plainNumber = "";
  // Remove formatting from the phone number
  plainNumber = phoneNumber?.replace(/[^0-9]/g, "");
  return plainNumber;
};

export const getformattedPhoneNumber = (value) => {
  const numericInput = value?.replace(/[^0-9]/g, "").slice(0, 14); // Limit to 14 digits

  // Format the phone number as (XXX) XXX-XXXX
  let formattedNumber = numericInput;

  if (numericInput?.length > 3 && numericInput?.length <= 6) {
    // Format as (XXX) XXX
    formattedNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3)}`;
  } else if (numericInput?.length > 6) {
    // Format as (XXX) XXX-XXXX
    formattedNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(
      3,
      6
    )}-${numericInput.slice(6, 14)}`;
  }

  return formattedNumber || "";
};

//Homapege tutorials steps
export const HomeSteps = [
  {
    target: ".search-box",
    title: "Find What You Need with Search",
    content:
      "Quickly locate projects, tasks, and updates using the search bar.",
    disableBeacon: true,
  },
  {
    target: ".notify-drp",
    title: "Stay Informed with Notifications",
    content: "Keep track of important updates and changes in your projects.",
    disableBeacon: true,
  },
  {
    target: ".menuicon",
    title: "Explore More with the Menu",
    content: "Access additional features and settings from the menu.",
    disableBeacon: true,
  },
  {
    target: ".process-cover",
    title: "Track Progress with the Graph",
    content: "Monitor your project’s progress and milestones visually.",
    disableBeacon: true,
  },
  {
    target: ".create_project",
    title: "Get Started with a New Project",
    content: "Set up a project with all necessary details and team members.",
    disableBeacon: true,
  },
  {
    target: ".create_team",
    title: "Build Your Team",
    content: "Add and manage team members for efficient collaboration.",
    disableBeacon: true,
  },
  {
    target: ".create_task",
    title: "Streamline Work with Templates",
    content: "Create templates to standardize tasks and processes.",
    disableBeacon: true,
  },
];

//Homapege tutorials steps
export const BlankHomeSteps = [
  {
    target: ".notify-drp",
    title: "Stay Informed with Notifications",
    content: "Keep track of important updates and changes in your projects.",
    disableBeacon: true,
  },
  {
    target: ".menuicon",
    title: "Explore More with the Menu",
    content: "Access additional features and settings from the menu.",
    disableBeacon: true,
  },

  {
    target: ".create_project",
    title: "Get Started with a New Project",
    content: "Set up a project with all necessary details and team members.",
    disableBeacon: true,
  },
  {
    target: ".create_team",
    title: "Build Your Team",
    content: "Add and manage team members for efficient collaboration.",
    disableBeacon: true,
  },
  {
    target: ".create_task",
    title: "Streamline Work with Templates",
    content: "Create templates to standardize tasks and processes.",
    disableBeacon: true,
  },
];

// Function to format the timer in MM:SS format
export const formatTime = (timer) => {
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return `${String(minutes).padStart(2, "0")} : ${String(seconds).padStart(
    2,
    "0"
  )}`;
};

// Update user profile functionalities:
export const updateUserDetails = async (
  formValues,
  setError,
  setLoading,
  showToast = true
) => {
  setLoading(true);
  try {
    let formData = { ...formValues };
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => form.append(key, value));

    formData.phoneNumber = getPlainPhoneNumber(formData.phoneNumber);
    const response = await APIServices.post("/user/update-profile", form, {
      "Content-Type": "multipart/form-data",
    });

    if (response.message) {
      if (showToast) appToast(response.message, TOAST_TYPE.SUCCESS);
      return true;
    } else {
      if (showToast)
        appToast(
          response.data.message || ProfileCompletionStrings.errorOccurred,
          TOAST_TYPE.ERROR
        );
      return false;
    }
  } catch (err) {
    setError(ProfileCompletionStrings.failedToRegister);
    console.error("Error while Updating profile:", err);
  } finally {
    setLoading(false);
  }
};

// Helper function to format UTC offsets
export const getUtcOffsetLabel = (timezone) => {
  const offset = moment.tz(timezone).utcOffset();
  const sign = offset >= 0 ? "+" : "-";
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  return `(UTC${sign}${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")})`;
};

//function to validate the form of overview/details page
export const validateDetailsTabForm = (formValues, setErrors) => {
  let newErrors = {};

  if (!formValues.projectName) {
    newErrors.projectName = "Project Name is required.";
  }

  if (!formValues.organizationId) {
    newErrors.organizationId = "Organization is required.";
  }

  if (!formValues.startDate) {
    newErrors.startDate = "Project Start date is required.";
  }

  if (!formValues.endDate) {
    newErrors.endDate = "Project Due date is required.";
  } else if (
    formValues.startDate &&
    formValues.endDate &&
    formValues.endDate <= formValues.startDate
  ) {
    newErrors.endDate = "Project Due date must be after Project Start date.";
  }

  if (!formValues.estimatedPrice) {
    newErrors.estimatedPrice = "Estimated Value is required.";
  } else if (Number(formValues.estimatedPrice) <= 0) {
    newErrors.estimatedPrice = "Estimated Value must be a positive number.";
  }

  setErrors(newErrors);

  // Disable submit button if there are any errors or missing fields
  // setIsSubmitDisabled(Object.keys(newErrors).length > 0);
  return Object.keys(newErrors).length > 0;
};

export const validateMilestoneFields = (
  milestones,
  setErrors,
  saveTemplate
) => {
  let hasError = false;
  const newErrors = {};

  milestones.forEach((milestone, index) => {
    newErrors[index] = {};
    if (!milestone.name) {
      newErrors[index].name = "Please enter Title.";
      hasError = true;
    }
    if (!milestone.description) {
      newErrors[index].description = "Please enter Description.";
      hasError = true;
    }
    if (milestone.signOffRequired && !milestone.signOffTime) {
      newErrors[index].signOffTime = "Please select SignOff Time.";
      hasError = true;
    }
    if (milestone.feedbackRequest && !milestone.feedbackRequestTime) {
      newErrors[index].feedbackRequestTime =
        "Please select Feedback Request Time.";
      hasError = true;
    }
    if (!milestone.daysToComplete) {
      newErrors[index].daysToComplete =
        "Please enter number of Days to complete milestone.";
      hasError = true;
    }
    if (!milestone.assigneeId && !saveTemplate) {
      newErrors[index].assigneeId = "Please select Assignee.";
      hasError = true;
    }

    if (milestone.paymentDueRequired && !milestone.paymentDue) {
      newErrors[index].paymentDue = "Please enter PaymentDue.";
      hasError = true;
    }
  });
  console.log(newErrors);

  setErrors(newErrors);
  return !hasError;
};

// function to calculate the diff between two dates.. used in milstone add template.
export const calculateDateDiff = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Get the time difference in milliseconds
  const timeDiff = endDate - startDate;

  // Convert milliseconds to days
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
};

// function to format date in this format "oct 1"
export const formatDate = (dateString) => {
  // Convert the input string into a Date object
  const date = new Date(dateString);

  // Format the date to "Month Day"
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
  }).format(date);

  return formattedDate;
};
