import React, { useEffect, useState } from "react";
import loginLogo from "../../assets/img/login-logo2.svg";
import logoHover from "../../assets/img/login-logohover.svg";
import "../../assets/css/verification.css";
import { selectLoader, setCookies } from "../../utils/Helpers";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import { verifyEmailStrings } from "./strings/authStrings";
import { useAuth } from "../../contexts/AuthContext";

const AcceptInvite = () => {
  const { refreshToken } = useAuth();
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      verifyEmailToken(token);
    }
  }, [token]);

  const verifyEmailToken = async (token) => {
    let payload = {token}
    try {

      const response = await APIServices.post(`/user/accept-invitation`, payload);
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        refreshToken(token);
        setCookies("authToken", token);
        navigate(`/login`, { state: { isVerified: true } });
      } else {
        appToast(
          response.data.message || verifyEmailStrings.errorOccurred,
          TOAST_TYPE.ERROR
        );
        // navigate(`/verification-status`, { state: { isVerified: false } });
      }
    } catch (err) {
      setError(verifyEmailStrings.registrationError);
      console.error("Error during verification:", err);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="form_right verification_success">
      <div className="login-box">
        <div className="login-logo">
          <a className="happyLogo-cover" href="index.html">
            <img
              src={loginLogo}
              alt={verifyEmailStrings.happyEnding}
              className="happy-logo"
            />
            <img
              src={logoHover}
              alt={verifyEmailStrings.happyEnding}
              className="happy-logo2"
            />
          </a>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">{verifyEmailStrings.happyEnding}</p>
            <p className="login-box-smalltext">
              {verifyEmailStrings.verifyingEmail}
            </p>
            <form action="" method="post">
              {loading && selectLoader(70)}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptInvite;
