import React, { useEffect, useState } from "react";
import "../../assets/css/overview-updated.css";
import "../../assets/css/overview-requests.css";
import leftArrow from "../../assets/img/left-arrow.svg";
import notificationIcon from "../../assets/img/notification.svg";
import arrowDown from "../../assets/img/arrow-down.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { Dropdown, ProgressBar } from "react-bootstrap";
import UpdatedScope from "./updatedoverviewtabs/UpdatedScope";
import APIServices from "../../services/APIServices";
import { formatDate, selectLoader } from "../../utils/Helpers";
import ActivityTab from "./updatedoverviewtabs/ActivityTab";
import RequestTab from "./updatedoverviewtabs/RequestTab";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";

const UpdatedOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const projectDetails = location.state?.projectDetails;
  // const [projectId, setProjectId] = useState("");
  const [scopeData, setScopeData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [milestones, setMilestones] = useState([]);
  // const [statusLoader, setStatusLoader] = useState(false); // State to track loading
  const [pageLoading, setPageLoading] = useState(true); // State to track loading
  const [loading, setLoading] = useState(true); // State to track loading
  const [milestoneStatusOptions, setMilestoneStatusOptions] = useState([]); // State to track loading

  // Fetch the scope details from the API
  const fetchScopeDetails = async (projectId) => {
    try {
      const response = await APIServices.get(
        `/project-scope?projectId=${projectId}`
      );
      if (response.scope) {
        setScopeData(response.scope); // Set the scope data in state
      }
    } catch (error) {
      console.error("Error fetching scope details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetching project data from the backend
  const fetchProjectData = async (projectId) => {
    try {
      // Using axios or fetch to get data from your API
      const response = await APIServices.get(`/project/${projectId}`); // API call with project ID
      console.log(response);
      if (response.projectDetail) {
        setProjectData(response.projectDetail); // Set the response data in state
        setMilestones(response.milestones);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    } finally {
      setPageLoading(false); // Stop loading even if there is an error
    }
  };

  const fetchmileStoneStatus = async () => {
    try {
      const response = await APIServices.get(`/enum?item_type=milestonstatus`); // Adjust the URL as necessary
      if (response.length > 0) {
        setMilestoneStatusOptions(response);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleBackClick = () => {
    navigate("/"); // This goes back to the previous page
  };

  useEffect(() => {
    if (projectId) {
      fetchProjectData(projectId);
      fetchScopeDetails(projectId);
      fetchmileStoneStatus(projectId);
    }
  }, [projectId]);

  const updateMilestoneStatus = async (
    milestoneId,
    updateType = "inprogress"
  ) => {
    let currentStatusId = milestoneStatusOptions?.find(
      (x) => x.value == "inprogress"
    );
    console.log(currentStatusId._id);
    if (currentStatusId._id) {
      let payload = {
        statusId: currentStatusId?._id,
      };
      try {
        // Example API call (adjust as per your actual API endpoint)
        const response = await APIServices.put(
          `/project-milestone/${milestoneId}/status`,
          payload
        ); // Adjust endpoint accordingly

        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          fetchProjectData(projectId);
        } else {
          appToast("Failed to update team status", TOAST_TYPE.ERROR);
        }
      } catch (error) {
        console.error("Error updating team status:", error);
        appToast("Failed to update team status", TOAST_TYPE.ERROR);
      }
    }
  };

  const HeaderContent = (
    <>
      <h2>Overview</h2>
      <div className="head-right">
        <Dropdown className="notify-drp">
          <Dropdown.Toggle as="a" className="dropdown-toggle">
            <img
              src={notificationIcon}
              alt="comp1"
              className="notification-img"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <p>ServiceMVP website</p>
              <span>2</span>
              <img src={arrowDown} className="arrow-down" />
            </Dropdown.Item>
            <Dropdown.Item>
              <p>True grit landing page</p>
              <span>6</span>
              <img src={arrowDown} className="arrow-down" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <a onClick={handleBackClick}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  if (pageLoading) {
    return <div className="nodata-loader">{selectLoader(70)}</div>;
  }

  return (
    <div className="wrapper">
      <div className="main-content overview_updated">
        <Header content={HeaderContent} />
        <section className="overview-sec">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-lg-4 col-md-12">
                <div className="left-sec-content">
                  <div className="card-box">
                    <div className="inner-card-box">
                      <div className="title-content">
                        <h3>{projectData?.projectName}</h3>
                      </div>
                      <div className="time-details">
                        <h3>#on-time</h3>
                      </div>
                    </div>
                    <div className="card-bottom">
                      <div className="milestone-cover">
                        <h5>
                          {formatDate(projectData?.startDate || new Date())}
                        </h5>
                        <div className="milestone-process">
                          <div className="milestone-process-bar w-100">
                            <span style={{ left: `calc(${40}% - 16px)` }}>
                              40%
                            </span>
                            <ProgressBar now={40} />
                          </div>
                        </div>
                        <h5>
                          {formatDate(projectData?.endDate || new Date())}
                        </h5>
                      </div>
                      <h5>
                        Modify by {projectData?.createdBy?.fullName}, Today
                      </h5>
                    </div>
                  </div>

                  {/* Running Milestones */}
                  {milestones?.running?.length > 0 && (
                    <div className="cardBox-content-item">
                      <div className="milestone-edit">
                        <h4>Running Milestones</h4>
                      </div>

                      {milestones?.running.map((milestone) => (
                        <div key={milestone._id}>
                          <div className="design-item">
                            <p>{milestone.name}</p>
                            <span>&nbsp;</span>
                            <small>{formatDate(milestone.startDate)}</small>
                          </div>
                          <div className="design-progress-item">
                            <ProgressBar now={milestone.percentage} />
                            <span className="percentage">
                              {milestone.percentage}%
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Upcoming Milestones */}
                  {milestones?.upcoming?.length > 0 && (
                    <div className="cardBox-content-item">
                      <div className="milestone-edit">
                        <h4>Upcoming Milestones</h4>
                      </div>

                      <div className="skill-rols-list-cover">
                        {milestones?.upcoming.map((milestone) => (
                          <div key={milestone._id} className="skill-rols-list">
                            <p>{milestone.name}</p>
                            <small
                              className="cursor_pointer"
                              onClick={() => {
                                updateMilestoneStatus(milestone._id);
                              }}
                            >
                              Start
                            </small>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="right-sec-content">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-updated-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-updated"
                        type="button"
                        role="tab"
                        aria-controls="pills-updated"
                        aria-selected="false"
                      >
                        Activity
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-requests-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-requests"
                        type="button"
                        role="tab"
                        aria-controls="pills-requests"
                        aria-selected="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.999"
                          height="19.532"
                          viewBox="0 0 22.999 19.532"
                        >
                          <g
                            id="Group_599"
                            data-name="Group 599"
                            transform="translate(4 2)"
                          >
                            <path
                              id="Icon_awesome-exchange-alt"
                              data-name="Icon awesome-exchange-alt"
                              d="M0,7.874V7.312a.844.844,0,0,1,.844-.844H13.5V4.78a.844.844,0,0,1,1.44-.6L17.753,7a.844.844,0,0,1,0,1.193L14.94,11a.844.844,0,0,1-1.44-.6V8.718H.844A.844.844,0,0,1,0,7.874Zm17.156,5.344H4.5V11.53a.844.844,0,0,0-1.44-.6L.247,13.746a.844.844,0,0,0,0,1.193L3.06,17.752a.844.844,0,0,0,1.44-.6V15.468H17.156A.844.844,0,0,0,18,14.624v-.562A.844.844,0,0,0,17.156,13.218Z"
                              transform="translate(0.999 -0.468)"
                              fill="#8f8f8f"
                            />
                            <g
                              id="Ellipse_84"
                              data-name="Ellipse 84"
                              transform="translate(-4 -2)"
                              fill="red"
                              stroke="#fff"
                              strokeWidth="0.5"
                            >
                              <circle cx="5" cy="5" r="5" stroke="none" />
                              <circle cx="5" cy="5" r="4.75" fill="none" />
                            </g>
                          </g>
                        </svg>
                        2 Request
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link "
                        id="pills-work-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-work"
                        type="button"
                        role="tab"
                        aria-controls="pills-work"
                        aria-selected="true"
                      >
                        Scope of work
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-feedback-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-feedback"
                        type="button"
                        role="tab"
                        aria-controls="pills-feedback"
                        aria-selected="true"
                      >
                        Feedback
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-updated"
                      role="tabpanel"
                      aria-labelledby="pills-updated-tab"
                    >
                      <ActivityTab
                        projectId={projectId}
                        updateProjectDetails={() => fetchProjectData(projectId)}
                      />
                    </div>
                    <div
                      className="tab-pane fade "
                      id="pills-requests"
                      role="tabpanel"
                      aria-labelledby="pills-requests-tab"
                    >
                      <RequestTab />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-work"
                      role="tabpanel"
                      aria-labelledby="pills-work-tab"
                    >
                      <div className="card-body-content pills-card-body-content">
                        <UpdatedScope
                          scopeData={scopeData}
                          // projectDetails={projectDetails}
                          loading={loading}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade "
                      id="pills-feedback"
                      role="tabpanel"
                      aria-labelledby="pills-feedback-tab"
                    >
                      <div className="text-center mt-3 milestones-comming-soon">
                        <p>Coming soon...</p>
                      </div>
                      {/* <FeedbackTab /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UpdatedOverview;
