import React from "react";
import { Modal } from "react-bootstrap";
import { selectLoader } from "../utils/Helpers";

const DeleteModal = ({ show, onHide, handleDelete, loading }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName=""
        centered
        className="deletModal"
      >
        {/* <Modal.Header closeButton={true}>
        </Modal.Header> */}
        <Modal.Body>
          <h2>Are you Sure?</h2>
          <p>Do you want to delete this item?</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onHide} className="cancel-btn">
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className="delet-btn"
            style={{ padding: !loading ? "" : "0" }}
          >
            {!loading ? "Delete" : selectLoader(35)}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteModal;
