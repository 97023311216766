import React from "react";
import akarFile from "../assets/img/akar-file.svg";

const EmptyList = ({buttonText, openModal}) => {
  return (
    <section className="blank-sec">
      <div className="container">
        <div className="row align-items-start">
          <div className="col-lg-12">
            <div className="blankList-box-cover">
              <div className="blankList-box">
                <img className="akarFile" src={akarFile} />

                <h2>You have no data at the moment.</h2>
                <p>To get started, click on the + button below to {buttonText}</p>

                <button className="add-new-organization" onClick={openModal} type="button">
                  + {buttonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmptyList;
