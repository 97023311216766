import React from "react";
import { Outlet } from "react-router-dom";
// import "../assets/css/signup.css";
import login from "../assets/img/login.png";
import shape from "../assets/img/shap-bg.png";

const AuthLayout = () => {
  return (
    <div className="login-page">
      <section className="login_section">
        <div className="login_inner">
          <div className="login_left">
            <div className="image_element">
              <iframe src="https://lottie.host/embed/96894531-8aa5-4a98-a2bf-0781322d6eb5/rN2tVLe271.json" className="img-gif"></iframe>
              <img src={login} alt="sign img" />
              <img src={shape} alt="shap bg" className="shap-bg" />
            </div>
          </div>
          {/* Render the child routes like Login, Signup */}
          <Outlet />
        </div>
      </section>
      {/* Render the child routes like Login, Signup */}
    </div>
  );
};

export default AuthLayout;
