import React, { createContext, useState } from 'react';

// Create a Context for the store
export const StoreContext = createContext();

// Create a provider component
export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({});

  return (
    <StoreContext.Provider value={[store, setStore]}>
      {children}
    </StoreContext.Provider>
  );
};
