import React, { useEffect, useState } from "react";
import "../../assets/css/overview-details.css";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../assets/img/left-arrow.svg";
import Header from "../../components/Header";
import DetailsTab from "./overviewtabs/DetailsTab";
import ScopeOfWork from "./overviewtabs/ScopeOfWork";
import SidebarModal from "../../components/SidebarModal";
import OrganizationForm from "./forms/OrganizationForm";
import TeamTab from "./overviewtabs/TeamTab";
import APIServices from "../../services/APIServices";
import MileStone from "./overviewtabs/MileStone";

const ProjectOverview = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [orgloading, setOrgLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const [milestoneProgress, setMilestoneProgress] = useState(null);
  const [fetchOrgs, setFetchOrgs] = useState(true);
  const [projectDetails, setprojectDetails] = useState("");
  const [organizations, setOrganizations] = useState([]);
  // State to track the completion status of each tab (default only first tab is available)
  const [completedTabs, setCompletedTabs] = useState({
    1: true,  // First tab is initially available
    2: true,
    3: true,
    4: true,
  });
  // Fetch organizations data
  const fetchOrganizations = async () => {
    setOrgLoading(true);
    try {
      const response = await APIServices.get(`/organizations`); // Adjust the URL as necessary
      if (response.organizations) {
        // Map the response to match the react-select option format
        const orgOptions = response.organizations.map((org) => ({
          label: org.name, // Display name as label
          value: org._id, // Use _id as value
        }));
        setOrganizations(orgOptions);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setOrgLoading(false);
    }
  };

  // Fetch organizations from API
  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrgs]);

  const handleBackClick = () => {
    if (currentTab == 1) {
      navigate("/"); // This goes back to the previous page
    } else if (currentTab == 4 && milestoneProgress != null) {
      setMilestoneProgress(null);
    } else {
      setCurrentTab(currentTab - 1);
    }
  };

  const toggleModal = (isOpen) => {
    setModalOpen(isOpen);
  };

  const HeaderContent = (
    <>
      <h2>Overview</h2>
      <div className="head-right">
        <a onClick={handleBackClick}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  // useEffect(() => {
  //   if (projectDetails) {
  //     console.log(projectDetails);
  //   }
  // }, [projectDetails]);

  const closeAndUpdateList = () => {
    toggleModal(false);
    setFetchOrgs((prevState) => !prevState);
  };

  const ModalContent = (
    <>
      <OrganizationForm
        // initialData={editingOrg}
        closeModal={closeAndUpdateList}
      />
    </>
  );
  return (
    <div className="wrapper create_project">
      <div className="main-content">
        <Header content={HeaderContent} />
        <section className="overview-sec">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-lg-12 col-md-12">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${currentTab == 1 ? "active" : ""}`}
                      id="pills-updated-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-updated"
                      type="button"
                      role="tab"
                      aria-controls="pills-updated"
                      aria-selected="false"
                      onClick={() => {
                        setCurrentTab(1);
                      }}
                    >
                      Details
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${currentTab == 2 ? "active" : ""}`}
                      id="pills-feedback-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-feedback"
                      type="button"
                      role="tab"
                      aria-controls="pills-feedback"
                      aria-selected="true"
                      onClick={() => {
                        setCurrentTab(2);
                      }}
                      disabled={!completedTabs[2]}
                    >
                      Team
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${currentTab == 3 ? "active" : ""}`}
                      id="pills-requests-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-requests"
                      type="button"
                      role="tab"
                      aria-controls="pills-requests"
                      aria-selected="true"
                      onClick={() => {
                        setCurrentTab(3);
                      }}
                      disabled={!completedTabs[3]}
                    >
                      Scope of Work
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${currentTab == 4 ? "active" : ""}`}
                      id="pills-work-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-work"
                      type="button"
                      role="tab"
                      aria-controls="pills-work"
                      aria-selected="true"
                      onClick={() => {
                        setMilestoneProgress(null);
                        setCurrentTab(4);
                      }}
                      disabled={!completedTabs[4]}
                    >
                      Milestones
                    </button>
                  </li>
                </ul>

                <div className="tab-content " id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      currentTab == 1 ? "active show" : ""
                    }`}
                    id="pills-updated"
                    role="tabpanel"
                    aria-labelledby="pills-updated-tab"
                  >
                    <DetailsTab
                      openModal={() => toggleModal(true)}
                      setCurrentTab={setCurrentTab}
                      setCompletedTabs={setCompletedTabs}
                      setprojectDetails={setprojectDetails}
                      organizationsList={organizations}
                      orgloading={orgloading}
                    />
                  </div>

                  <div
                    className={`tab-pane fade ${
                      currentTab == 2 ? "active show" : ""
                    }`}
                    id="pills-feedback"
                    role="tabpanel"
                    aria-labelledby="pills-feedback-tab"
                  >
                    <TeamTab
                      projectDetails={projectDetails}
                      setCurrentTab={setCurrentTab}
                      setCompletedTabs={setCompletedTabs}
                      organizationsList={organizations}
                    />
                  </div>

                  <div
                    className={`tab-pane fade ${
                      currentTab == 3 ? "active show" : ""
                    }`}
                    id="pills-requests"
                    role="tabpanel"
                    aria-labelledby="pills-requests-tab"
                  >
                    <div className="card-body-content">
                      <ScopeOfWork
                        projectDetails={projectDetails}
                        setCurrentTab={setCurrentTab}
                        setCompletedTabs={setCompletedTabs}
                      />
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${
                      currentTab == 4 ? "active show" : ""
                    }`}
                    id="pills-work"
                    role="tabpanel"
                    aria-labelledby="pills-work-tab"
                  >
                    <div className="card-body-content">
                      <MileStone
                        projectDetails={projectDetails}
                        milestoneProgress={milestoneProgress}
                        setMilestoneProgress={setMilestoneProgress}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Modal Component */}
        <SidebarModal
          extraClass={"edit-profile-modal client_modal"}
          show={modalOpen}
          onHide={() => toggleModal(false)}
          headerTitle={"Add New Organization"}
        >
          {ModalContent}
        </SidebarModal>
      </div>
    </div>
  );
};

export default ProjectOverview;
