import React, { useState } from "react";

const RequestListTab = ({ setParentTab }) => {
  const [activeTab, setActiveTab] = useState("open");
  return (
    <div className="card-body-content request-content">
      <div className="bg-white btn-tab-group">
        <button
          className={`btn btn-custom ${
            activeTab === "open" ? "btn-dark" : "btn-light"
          }`}
          onClick={() => setActiveTab("open")}
        >
          Open
        </button>
        <button
          className={`btn btn-custom  ${
            activeTab === "Closed" ? "btn-dark" : "btn-light"
          }`}
          onClick={() => setActiveTab("Closed")}
        >
          Closed
        </button>
      </div>

      <div
        className="add_landing_card"
        onClick={() => setParentTab("requestDetails")}
      >
        <div className="card_header">
          <h4>Need to add new landing page</h4>
          <div className="head_right_btn">
            <a className="card_btn" href="#">
              Sign-off
            </a>
            <span>3</span>
            <div className="arrow_icon">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 18.252L18 12.252L12 6.25195"
                  stroke="#7c7c7c"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="card_cantent">
          <p>
            The website will serve as an online platform for customers to learn
            about the company...
          </p>
          <span>Requested by Ravi, Yesterday</span>
        </div>
      </div>
      <div className="add_landing_card">
        <div className="card_header">
          <h4>Remove team page</h4>
          <div className="head_right_btn">
            <a className="card_btn" href="#">
              Change Order
            </a>
            <span>1</span>
            <div className="arrow_icon">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 18.252L18 12.252L12 6.25195"
                  stroke="#7c7c7c"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="card_cantent">
          <p>
            The website will serve as an online platform for customers to learn
            about the company...
          </p>
          <span>Requested by Robert, April 20, 2023</span>
        </div>
      </div>
      <div className="add_landing_card active">
        <div className="card_header">
          <h4>Design Feedback Request</h4>
          <div className="head_right_btn">
            <a className="card_btn" href="#">
              Feedback Request
            </a>
            <span>3</span>
            <div className="arrow_icon">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 18.252L18 12.252L12 6.25195"
                  stroke="#7c7c7c"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="card_cantent">
          <p>Please let us know how the design process worked for you.</p>
          <span>Requested by Rocco, April 20, 2023</span>
        </div>
      </div>
      <div className="user-chatBox-cover">
        <ul className="chat-btn-list">
          <li>
            <a href="">+ Sign-off</a>
          </li>
          <li>
            <a href="">+ Change Order</a>
          </li>
          <li>
            <a href="">+ Request</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RequestListTab;
