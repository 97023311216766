import React, { useEffect, useState } from "react";
import "../../assets/css/verify.css";
import loginLogo from "../../assets/img/login-logo2.svg";
import logoHover from "../../assets/img/login-logohover.svg";
import { useStore } from "../../hooks/useStore";
import {
  formatTime,
  selectLoader,
  setCookies,
  userType,
} from "../../utils/Helpers";
import APIServices from "../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import { useLocation, useNavigate } from "react-router-dom";
import { verificationStrings } from "./strings/authStrings";
import { useAuth } from "../../contexts/AuthContext";

const Verification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshToken } = useAuth();
  const isEmail = location.state?.isEmail;
  const disableFields = location.state?.disableFields;
  const [store] = useStore();
  const [loading, setLoading] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [countryDialCode, setCountryDialCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  // New states for resend OTP logic
  const [isResendVisible, setResendVisible] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [resendTimer, setResendTimer] = useState(180); // Timer in seconds (1 minute)

  useEffect(() => {
    if (store.userEmail) {
      setEmail(store.userEmail);
    }
    if (store.phoneNumber) {
      setPhoneNumber(store.phoneNumber);
    }
    if (store.countryDialCode) {
      setCountryDialCode(store.countryDialCode);
    }
    // Show "Resend OTP" after 1 minute on page load
    const initialTimer = setTimeout(() => {
      setResendVisible(true);
    }, 180000); // 60000ms = 1 minute

    return () => clearTimeout(initialTimer); // Cleanup timeout on unmount
  }, []);

  useEffect(() => {
    let interval;

    if (resendTimer > 0 && !isResendVisible) {
      // Start the countdown if the timer is active and "Resend OTP" is not visible
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      // When the timer hits 0, show the "Resend OTP" button
      setResendVisible(true);
      setResendTimer(180); // Reset timer for future use
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [resendTimer, isResendVisible]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    // Only allow digits 0-9
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Reset error message if any
      setOtpError("");

      // Move focus to the next input box when valid
      if (value && index < otp.length - 1) {
        document.getElementById(`digit-${index + 2}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`digit-${index}`).focus();
    }
  };

  const handleResendOtp = async () => {
    if (resendAttempts >= 2) {
      return; // Stop if the user has exceeded the maximum resend attempts
    }

    setOtp(["", "", "", "", "", ""]);
    const url = "/register-login";
    const payload = isEmail
      ? { email, userType }
      : { countryDialCode, phoneNumber, userType };

    setLoading(true);

    try {
      const response = await APIServices.post(url, payload);
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        setResendVisible(false); // Hide "Resend OTP" button for 1 minute
        setResendAttempts(resendAttempts + 1); // Increment the attempts count

        // Start the 1-minute timer again
        setResendTimer(180);
      } else {
        appToast(
          response.data.message || verificationStrings.errorOccurred,
          TOAST_TYPE.ERROR
        );
      }
    } catch (err) {
      console.error(`${verificationStrings.errorOccurred}`, err);
    } finally {
      setLoading(false);
    }
  };

  const validateOtp = () => {
    // Check if OTP is complete
    if (otp.join("").length !== 6) {
      setOtpError("Please enter a valid 6-digit OTP.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateOtp()) return;
    let otpString = otp.join("");
    const url = "/verify-otp";
    const payload = isEmail
      ? {
          email: email,
          otp: otpString,
        }
      : {
          countryDialCode: countryDialCode,
          phoneNumber: phoneNumber,
          otp: otpString,
        };

    try {
      setLoading(true);
      const response = await APIServices.post(url, payload);
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        refreshToken(response.token);
        setCookies("authToken", response.token);
        if (response.isRegistered) {
          navigate(`/projects`);
        } else {
          navigate(`/complete-profile`);
        }
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (err) {
      console.error("Error while verifying email", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form_right varify_email">
      <div className="login-box">
        <div className="login-logo">
          <a className="happyLogo-cover" href="index.html">
            <img
              src={loginLogo}
              alt={verificationStrings.happyEnding}
              className="happy-logo"
            />
            <img
              src={logoHover}
              alt={verificationStrings.happyEnding}
              className="happy-logo2"
            />
          </a>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">{verificationStrings.happyEnding}</p>
            <p className="login-box-smalltext">
              {isEmail
                ? verificationStrings.setEmailCode
                : verificationStrings.setPhoneCode}{" "}
              <a>{isEmail ? email : countryDialCode + phoneNumber}</a>
            </p>
            <form onSubmit={handleSubmit}>
              <p className="email-msg-text">
                {isEmail
                  ? verificationStrings.emailSentMessage
                  : verificationStrings.phoneSentMessage}
              </p>

              <div className={`row form-floating-tab3`}>
                <div className="col-lg-12">
                  <div className="otp_group">
                    {otp.map((digit, index) => (
                      <div className="otp_input_form" key={index}>
                        <input
                          type="text"
                          className={`form-control ${
                            otpError ? "form-invalid" : ""
                          }`}
                          id={`digit-${index + 1}`}
                          name={`digit-${index + 1}`}
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleOtpChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="otp-massage">
                    {otpError && (
                      <p className="error-message" style={{ bottom: "0" }}>
                        {otpError}
                      </p>
                    )}
                    {!isResendVisible && resendAttempts < 2 && (
                      <p className="resend_code">
                        Resend Otp in {formatTime(resendTimer)}
                      </p>
                    )}
                    {isResendVisible && resendAttempts < 2 && (
                      <p className="resend_code" onClick={handleResendOtp}>
                        {verificationStrings.resendOtp}
                      </p>
                    )}
                    {resendAttempts >= 2 && (
                      <p className="resend_code_disabled">
                        {verificationStrings.noMoreResends}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button
                    type="submit"
                    disabled={isDisable}
                    className="btn btn-black btn-block"
                  >
                    {!loading
                      ? verificationStrings.verifyButton
                      : selectLoader(35)}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
