import React, { useState } from "react";
import uploadSvg from "../../../../assets/img/upload.svg";
import sendSvg from "../../../../assets/img/send.svg";
import SidebarModal from "../../../../components/SidebarModal";
import addIcon from "../../../../assets/img/add.svg";
import deleteBtn from "../../../../assets/img/delete.svg";
import filePic from "../../../../assets/img/file-picture.svg";
import {
  addQuoteModalContent,
  updateModalContent,
} from "../../../../utils/modalContent";

const RequestDetails = () => {
  const [modals, setModals] = useState({
    addQuoteModal: false,
  });
  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen,
    }));
  };
  const addModal = (
    <>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className=" expires-form-group">
            <label className="label-title d-flex align-items-center mb-0 me-3">
              Expires
            </label>
            <div className="select-option-box">
              <select className="select-option">
                <option>30 Days</option>
                <option>32 Days</option>
              </select>
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
              </svg>
            </div>
            {/* <Select
              options={priorityOptions}
              value={priorityOptions.find(
                (option) => option.value === milestone.priority
              )}
              onChange={(option) =>
                handleInputChange(index, "Expires", option.value)
              }
              styles={{
                container: (base) => ({
                  ...base,
                  maxWidth: "150px",
                }),
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="form-group">
            <div className="new-addition-list">
              <a href="#" className="active">
                <p>Option</p>
                <span>1</span>
              </a>
              <a href="#">
                <p>Option</p>
                <span>2</span>
              </a>
              <a href="#">
                <p>&nbsp;</p>
                <img src={addIcon} className="add-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="text"
              placeholder="Request Title"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Write Request Description"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group-items">
            <div className="attech-file-cover">
              <label className="attech-btn">
                {/* <img src={attechFile} alt="comp1" className="attechFile" /> */}
                Line items
              </label>

              <label className="add-btn-text cursor_pointer">+ Add</label>
            </div>

            <div className="user-box-cover">
              <div className="username">
                <div className="username-content">
                  <p>Design banner</p>
                </div>
              </div>
              <img src={deleteBtn} alt="comp1" className="deleteBtn" />
            </div>
            <div className="user-box-cover">
              <div className="username">
                <div className="username-content">
                  <p>
                    Design Banner so we can move on to the next phase of the
                    project
                  </p>
                </div>
              </div>
              <img src={deleteBtn} alt="comp1" className="deleteBtn" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="text"
              placeholder="Additional Cost"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="date-cover justify-content-end">
              {/* <a href="" className="start-date">
                + Due date
              </a> */}
              <a href="" className="due-date-text">
                Number of Days
              </a>
            </div>
          </div>
        </div>
      </div>

      <button className="btn-black">Submit</button>
    </>
  );
  return (
    <div className="card-body-content">
      <div className="user-chatBox-cover">
        <ul className="chat-btn-list">
          <li>
            <a onClick={() => toggleModal("addQuoteModal", true)}>
              + Add Quote
            </a>
          </li>
        </ul>
        <div className="chat-msg-cover">
          <div className="send-msg-text">
            <img src={uploadSvg} className="upload-icon" />
            <textarea
              id="autoResizableTextarea"
              className="formControl"
              placeholder="Type your message"
            />
            <div className="send-msg-cover">
              <img src={sendSvg} />
            </div>
          </div>
        </div>
      </div>
      <div className="request_details_page request_details_page_main">
        {/* <div className="request_content_cover sign_off_request">
          <div className="request_details_avtar">
            <div className="avtar_inner">RJ</div>
          </div>
          <div className="request_details_content">
            <div className="request_header">
              <div className="request_header_inner">
                <div className="date_time">
                  Ravi Jada, Yesterday at 10:45 AM
                </div>
                <h4>Sign-off Request</h4>
              </div>
              <div className="request_header_bottom">
                <div className="request_header_left">
                  <p>Design</p>
                  <span>Completed</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "75%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <small>96%</small>
                </div>
                <div className="request_header_right">
                  <a className="pending_btn" href="#">
                    Pending
                  </a>
                  <a className="cancle_btn" href="#">
                    Cancel
                  </a>
                </div>
              </div>
            </div>
            <div className="file_uploaded_cover">
              <div className="file_uploaded_inner">
                <div className="file_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-image"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                  </svg>
                  <div className="file_name">
                    <h6>screenshot.jpg</h6>
                    <span className="file_size">123KB</span>
                  </div>
                </div>
                <div className="file_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-image"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                  </svg>
                  <div className="file_name">
                    <h6>screenshot.jpg</h6>
                    <span className="file_size">123KB</span>
                  </div>
                </div>
                <div className="file_counter">+2</div>
              </div>
              <div className="time_reminder">
                <div className="time_left_cls">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-alarm"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z" />
                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1" />
                  </svg>
                  -23:30 Time left
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-exclamation-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                  </svg>
                  <div className="exclamation_content">
                    New Request needs delivered by without delay just like
                    sign-off time. We dont need a start date, but could have a
                    due date.
                  </div>
                </div>
                <p>
                  Sign-off requests within 24 hours, as any unanswered requests
                  will be automatically approved.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="request_content_cover need_to_add">
          <div className="request_details_avtar">
            <div className="avtar_inner yellow_bg">TS</div>
          </div>
          <div className="request_details_content">
            <div className="request_header">
              <div className="request_header_inner">
                <div className="date_time">Requested by TJ, Yesterday</div>
                <h4>Need to add new landing page</h4>
                <p className="pera_text">
                  The goal of this project is to develop a responsive website
                  for a small business. The website will serve as an online
                  platform for customers to learn about the company, browse
                  products and services.
                </p>
              </div>
              <div className="file_uploaded_inner">
                <div className="file_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-image"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                  </svg>
                  <div className="file_name">
                    <h6>screenshot.jpg</h6>
                    <span className="file_size">123KB</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="date_btn_cls">
              <span>Due on May 20, 2023</span>
              <a href="#">Submitted</a>
            </div>
          </div>
        </div>
        <div className="request_content_cover ">
          <div className="request_details_avtar">
            <div className="avtar_inner">RJ</div>
          </div>
          <div className="request_details_content">
            <div className="request_header">
              <div className="request_header_inner">
                <div className="date_time">Ravi, Yesterday at 10:50 AM</div>
                <h4>forgot to add the last two wire-frames, please attach</h4>
              </div>
            </div>
          </div>
        </div> */}
        <div className="wire-frame_design">
          <div className="request_header_inner">
            <label>
              <b className="darkblue-text">New Request!</b> Design new landing
              page
            </label>
            <p>
              The goal of this project is to develop a responsive website for a
              small business. The website will serve as an online platform for
              customers to learn about the company, browse products and
              services.
            </p>
          </div>
          <div className="file_uploaded_inner">
            <div className="file_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.813"
                height="24.929"
                viewBox="0 0 21.813 24.929"
              >
                <path
                  id="Icon_metro-file-picture"
                  data-name="Icon metro-file-picture"
                  d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                  transform="translate(-4.499 -1.928)"
                  fill="#818181"
                />
              </svg>

              <div className="file_name">
                <h6>hero banner.jpg</h6>
                <span className="file_size">123KB</span>
              </div>
            </div>
            <div className="file_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.813"
                height="24.929"
                viewBox="0 0 21.813 24.929"
              >
                <path
                  id="Icon_metro-file-picture"
                  data-name="Icon metro-file-picture"
                  d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                  transform="translate(-4.499 -1.928)"
                  fill="#818181"
                />
              </svg>

              <div className="file_name">
                <h6>banner.jpg</h6>
                <span className="file_size">123KB</span>
              </div>
            </div>
            <div className="file_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.813"
                height="24.929"
                viewBox="0 0 21.813 24.929"
              >
                <path
                  id="Icon_metro-file-picture"
                  data-name="Icon metro-file-picture"
                  d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                  transform="translate(-4.499 -1.928)"
                  fill="#818181"
                />
              </svg>

              <div className="file_name">
                <h6>screenshot.jpg</h6>
                <span className="file_size">123KB</span>
              </div>
            </div>
          </div>
          <div className="request_footer_inner">
            <button className="edit-btn">Edit</button>
            <p>Due on May 20, 2023</p>
          </div>
        </div>
        <div className="chat-time">Rocco - 9:46 AM Today</div>
        {/* <div className="request_content_cover additional_work_cls">
          <div className="request_details_avtar">
            <div className="avtar_inner yellow_bg">TS</div>
          </div>
          <div className="request_details_content">
            <div className="request_header">
              <div className="request_header_inner">
                <div className="date_time">Requested by TJ, Yesterday</div>
                <h4>Additional Work</h4>
              </div>
              <div className="radio_btn_section">
                <div className="radio_inner_sec">
                  <div className="radio_btn">
                    <input type="radio" id="test1" name="radio-group" />
                    <label for="test1"></label>
                  </div>
                  <div className="radio_btn_content">
                    <div className="radio_head">
                      <h4>Option 1</h4>
                      <span>2 days</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                        />
                      </svg>
                      <small>$600</small>
                    </div>
                    <div className="radio_bottom_cont">
                      <p>
                        This landing page takes two days time, which will
                        additional cost of the project attached date, May 20,
                        2023
                      </p>
                    </div>
                  </div>
                </div>
                <div className="radio_inner_sec">
                  <div className="radio_btn">
                    <input type="radio" id="test2" name="radio-group" />
                    <label for="test2"></label>
                  </div>
                  <div className="radio_btn_content">
                    <div className="radio_head">
                      <h4>Option 2</h4>
                      <span>2 days</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                        />
                      </svg>
                      <small>$600</small>
                    </div>
                    <div className="radio_bottom_cont">
                      <p>
                        This landing page takes two days time, which will
                        additional cost of the project attached date, May 20,
                        2023
                      </p>
                    </div>
                    <div className="waiting_btn">
                      <a href="#">Waiting for approvel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="request_content_cover ">
          <div className="request_details_avtar">
            <div className="avtar_inner yellow_bg">TJ</div>
          </div>
          <div className="request_details_content">
            <div className="request_header">
              <div className="request_header_inner">
                <div className="date_time">TJ, Yesterday at 10:50 AM</div>
                <div className="accept_txt">Successful Accepted!</div>
                <h4>The new timeline is May 20, 2023</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="request_content_cover ">
          <div className="request_details_avtar">
            <div className="avtar_inner yellow_bg">TJ</div>
          </div>
          <div className="request_details_content">
            <div className="request_header">
              <div className="request_header_inner">
                <div className="date_time">TJ, Yesterday at 10:50 AM</div>
                <div className="declined_txt">Quote 1 is declined!</div>
                <h4>The new timeline is May 20, 2023</h4>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="request-details-chat chat-bubbleYou-cover">
        <div className="bluebg-card chat-bubble">
          <p>
            <b className="darkblue-text">New Request! </b> has been approved by{" "}
            <b className="lightblue-text">@Ravi </b>
          </p>
        </div>
        <div className="chat-time">Ravi - 9:47 AM Today</div>
      </div>
      <div className="request-details-chat chat-bubbleYou-cover">
        <div className="garybg-card chat-bubble">
          <p>
            <b className="lightblue-text">@Ravi </b> You forgot to include the last
            two wireframes. Could you please attach them?{" "}
          </p>
        </div>
        <div className="chat-time">Ravi - 9:56 AM Today</div>
      </div>
      <div className="request_details_page request-chat-bubbleMe">
        <div className="wire-frame_design">
          <div className="request_header_inner">
            <p>
              Hey <b className="lightblue-text">@Ravi,</b> here it is.
            </p>
          </div>
          <div className="file_uploaded_inner">
            <div className="file_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.813"
                height="24.929"
                viewBox="0 0 21.813 24.929"
              >
                <path
                  id="Icon_metro-file-picture"
                  data-name="Icon metro-file-picture"
                  d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                  transform="translate(-4.499 -1.928)"
                  fill="#818181"
                />
              </svg>

              <div className="file_name">
                <h6>hero banner.jpg</h6>
                <span className="file_size">123KB</span>
              </div>
            </div>
            <div className="file_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.813"
                height="24.929"
                viewBox="0 0 21.813 24.929"
              >
                <path
                  id="Icon_metro-file-picture"
                  data-name="Icon metro-file-picture"
                  d="M23.2,23.741H7.615V20.625l4.674-7.79,6.4,7.79,4.5-3.116Zm0-10.128a2.337,2.337,0,1,1-2.337-2.337A2.337,2.337,0,0,1,23.2,13.614ZM25.285,7.5a22.075,22.075,0,0,0-2.122-2.427,22.08,22.08,0,0,0-2.427-2.122,3.883,3.883,0,0,0-2.214-1.027H6.446A1.95,1.95,0,0,0,4.5,3.876V24.91a1.95,1.95,0,0,0,1.948,1.948H24.364a1.95,1.95,0,0,0,1.948-1.948V9.718A3.883,3.883,0,0,0,25.285,7.5ZM22.061,6.179A21.249,21.249,0,0,1,23.828,8.16H20.08V4.413a21.228,21.228,0,0,1,1.981,1.767Zm2.693,18.73a.4.4,0,0,1-.39.39H6.446a.4.4,0,0,1-.39-.39V3.876a.4.4,0,0,1,.39-.39H18.521V8.939a.779.779,0,0,0,.779.779h5.453Z"
                  transform="translate(-4.499 -1.928)"
                  fill="#818181"
                />
              </svg>

              <div className="file_name">
                <h6>banner.jpg</h6>
                <span className="file_size">123KB</span>
              </div>
            </div>
          </div>
        </div>
        <div className="chat-time">Rocco - 9:46 AM Today</div>
      </div>

      <div className="chat-bubbleMe-cover">
        <div className="bluebg-card chat-bubble garybg-card">
          <p>
            Hey <b className="lightblue-text">@Revi</b> please give me a few days to provide a quotation and time line.
          </p>
        </div>

        <div className="chat-time">Rocco - 9:46 AM Today</div>
      </div>


      <div className="request_details_page request_details_page_main">
       
    
     
    
        <div className="request_content_cover additional_work_cls">
        
          
        <div className="radio_btn_section">
          <span className="quote-text">Quote!</span>
                <div className="radio_inner_sec">
                  <div className="radio_btn">
                    <input type="radio" id="test1" name="radio-group" />
                    <label for="test1"></label>
                  </div>
                  <div className="radio_btn_content">
                    <div className="radio_head">
                      <h4>Option 1</h4>
                      <span>2 days</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                        />
                      </svg>
                      <small>$600</small>
                    </div>
                    <div className="radio_bottom_cont">
                      <p>
                        This landing page takes two days time, which will
                        additional cost of the project attached date, May 20,
                        2023
                      </p>
                      <b className="date-attected">Attected date, may 19, 2023</b>
                    </div>
                  </div>
                </div>
                <div className="radio_inner_sec">
                  <div className="radio_btn">
                    <input type="radio" id="test2" name="radio-group" />
                    <label for="test2"></label>
                  </div>
                  <div className="radio_btn_content">
                    <div className="radio_head">
                      <h4>Option 2</h4>
                      <span>2 days</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                        />
                      </svg>
                      <small>$600</small>
                    </div>
                    <div className="radio_bottom_cont">
                      <p>
                        This landing page takes two days time, which will
                        additional cost of the project attached date, May 20,
                        2023
                      </p>
                      <b className="date-attected"> Attected date, may 19, 2023</b>
                    </div>
                    {/* <div className="waiting_btn">
                      <a href="#">Waiting for approvel</a>
                    </div> */}
                  </div>
                </div>
                <button className="edit-btn">Edit</button>
              </div>
        </div>
      
      </div>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.addQuoteModal}
        onHide={() => toggleModal("addQuoteModal", false)}
        headerTitle={"Quote"}
      >
        {/* {addQuoteModalContent()} */}
        {addModal}
      </SidebarModal>
    </div>
  );
};

export default RequestDetails;
