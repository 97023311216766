
// Verification page strings: componentName: "verification.js"
export const verificationStrings = {
  happyEnding: "Happy ending",
  verifyYourAccount: "Verify your account",
  verifyYourEmail: "Verify Your Email",
  verifyYourPhone: "Verify Your Phone",
  email: "Email",
  phone: "Phone",
  emailAddressPlaceholder: "Email address",
  emailSentMessage:
    "We’ve sent an email to verify your email address and activate your account. The link in the email will expire in 24 hours.",
  phoneSentMessage:
    "We’ve sent a verification code to your phone number. Please enter the code to verify your number and activate your account. The code will expire in 10 minutes.",
  resendCode: "Did not receive OTP? Resend Code",
  verifyButton: "Verify & Next",
  continueButton: "Continue",
  setEmailCode: "Enter the code we’ve send by email to ",
  setPhoneCode: "Enter the code we’ve send by text to ",
  resendOtp: "Resend OTP",
  errorOccurred: "An error occurred. Please try again.",
};

// verification-status page strings: componentName: "verificationSuccess.js"
export const verificationStatusStrings = {
  happyEnding: "Happy Ending",
  verifyAccount: "Verify your account",
  verificationSuccessful: "Verification Successful",
  verificationUnSuccessful: "Verification Unsuccessful",
  successMessage: "Your email address has been successfully verified, You can now start using Happy Ending to enjoy all its features.",
  unSuccessMassage: "It seems we couldn’t verify your email address. Please check if the email is correct and try again.",
  goToHome: "Go to home",
  resendEmail: "Resend Verification Email",
  emailPlaceholder: "Email",
};

// verify-email page strings: componentName: "verifyEmail.js"
export const verifyEmailStrings = {
  happyEnding: "Happy Ending",
  verifyingEmail: "Accepting Invitation",
  errorOccurred: "An error occurred",
  registrationError: "Failed to register account.",
};

// Login page strings: componentName: "Login.js"
export const loginStrings = {
  happyEnding: "Happy ending",
  verifyYourAccount: "Verify your account",
  verifyYourEmail: "Verify Your Email",
  verifyYourPhone: "Verify Your Phone",
  email: "Email",
  phone: "Phone",
  emailAddressPlaceholder: "Ex: yourname@mail.com",
  loginButton: "Login",
  continueButton: "Continue",
  loginToContinue: "Please login to continue.",
  registerAccount: "Don't have an account?",
  register: "Register",
  errorOccurred: "An error occurred. Please try again.",
  errorWhile: "Error while verifying "
};

// Complete-profile page strings: componentName: "Profilecomplition.js"
export const ProfileCompletionStrings = {
  happyEnding: "Happy Ending",
  completeYourProfile: "Complete your profile",
  fullNamePlaceholder: "Full Name",
  emailPlaceholder: "Email",
  companyNamePlaceholder: "Company Name",
  passwordPlaceholder: "Password",
  confirmPasswordPlaceholder: "Confirm Password",
  termsText: "By signing up, you agree to our",
  termsOfUse: "Terms of Use",
  privacyPolicy: "Privacy Policy",
  failedToRegister: "Failed to register. Please try again.",
  errorOccurred: "An error occurred. Please try again.",
  goToHome: "Go to home"
};