import { useContext } from 'react';
import { StoreContext } from '../contexts/StoreContext';

export const useStore = () => {
    const [store, setStore] = useContext(StoreContext);
  
    // Helper function to update the store
    const updateStore = (newState) => {
      setStore((prevStore) => ({
        ...prevStore,
        ...newState,
      }));
    };
  
    return [store, updateStore];
  };
  