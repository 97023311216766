import RoutesComponent from "./Routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


function App() {
  return (
    <div className="">
      <div className="App">
        <RoutesComponent />
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
}

export default App;
