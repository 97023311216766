import React, { useEffect, useState } from "react";
import "../../assets/css/home.css";
import "../../assets/css/blank-project-list.css";
import notificationIcon from "../../assets/img/notification.svg";
import featherMenu from "../../assets/img/feather-menu.svg";
import { Dropdown, ProgressBar } from "react-bootstrap";
import Header from "../../components/Header";
import akarFile from "../../assets/img/akar-file.svg";
import searchIcon from "../../assets/img/search.svg";
import userIcon from "../../assets/img/nick-user2.png";
import listIcon from "../../assets/img/list.svg";
import gridIcon from "../../assets/img/grid.svg";
import listWhiteIcon from "../../assets/img/listwhite.svg";
import gridWhiteIcon from "../../assets/img/gridwhite.svg";
import chatBoxes from "../../assets/img/chatboxes.svg";
import { useAuth } from "../../contexts/AuthContext";
import ActionMenu from "../../components/ActionMenu";
import arrowDown from "../../assets/img/arrow-down.svg";
import uploadIcon from "../../assets/img/upload2.svg";
import SidebarModal from "../../components/SidebarModal";
import PhoneNumberInput from "../../components/PhoneInput";
import Joyride from "react-joyride";
import {
  BlankHomeSteps,
  formatDate,
  formFields,
  getCookies,
  getformattedPhoneNumber,
  getPlainPhoneNumber,
  getUserDetails,
  HomeSteps,
  PAGE_LIMIT,
  selectLoader,
  updateUserDetails,
  userType,
  validateEmail,
  validateForm,
  validatePhoneNumber,
} from "../../utils/Helpers";
import { ProjectListStrings } from "./strings/ProjectStrings";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import { Link, useNavigate } from "react-router-dom";
import APIServices from "../../services/APIServices";
import { useStore } from "../../hooks/useStore";
import UpdateProjectForm from "./forms/UpdateProjectForm";

const Home = () => {
  const { logout, fetchUserInfo, user, isUser1 } = useAuth();
  const navigate = useNavigate();
  const [store, setStore] = useStore();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [isPojectsAvailable, setPojectsAvailable] = useState(false);
  const [projects, setProjects] = useState([]);
  const [formValues, setFormValues] = useState(formFields);
  const [profileUrl, setProfileUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [runTour, setRunTour] = useState(false);
  const [activeTab, setActiveTab] = useState("pills-profile");
  const [projectId, setProjectId] = useState("");
  const [isVerified, setVerified] = useState({
    isEmailVerified: true,
    isPhoneNumberVerified: true,
  });
  const [modals, setModals] = useState({
    profileModal: false,
    updateProfile: false,
    updateProject: false,
  });

  const HandleAction = () => {
    console.log("clickedd");
  };

  // Handle tab change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  // Fetch details of the user.
  const fetchUserDetails = async (user) => {
    const token = getCookies("authToken");

    if (!token) return; // Early return if no token

    try {
      setPageLoading(true);
      const userDetails = await fetchUserInfo();

      // setDataLoading(true);
      if (userDetails) {
        const {
          email,
          countryDialCode,
          phoneNumber,
          fullName,
          countryCode,
          profilePic,
          companyId,
          isTotorialVisited,
          isEmailVerified,
          isPhoneNumberVerified,
        } = userDetails;

        // Update form values and verification status in a single state update
        setFormValues((prevValues) => ({
          ...prevValues,
          email,
          countryDialCode,
          phoneNumber: getformattedPhoneNumber(phoneNumber),
          fullName,
          countryCode,
          profilePic,
          companyName: companyId?.name,
          isTotorialVisited,
        }));

        setVerified({
          isEmailVerified,
          isPhoneNumberVerified,
        });

        // Trigger the tour based on tutorial visited status
        setRunTour(!isTotorialVisited);
        fetchProjects(userDetails._id);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchProjects = async (userId) => {
    try {
      const response = await APIServices.get(`/project/user/${userId}`);
      if (response.projects) {
        setProjects(response.projects);
        if (response.projects.length > 0) {
          setPojectsAvailable(true);
        }
      } else if (response?.data?.message) {
        appToast(response?.data?.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setPageLoading(false);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen,
    }));
  };

  // Helper function to clear errors for a specific field
  const clearError = (name) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    clearError(name); // Clear error for the current field
  };

  const handlePhoneChange = (val, countryDialCode) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      phoneNumber: val,
      countryDialCode: `${countryDialCode}`,
    }));

    clearError("phone"); // Clear error for phone field
  };

  const validate = () => {
    let tempErrors = validateForm(formValues);
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    let imageName = "";
    if (!file) {
      setFormValues({
        ...formValues,
        profilePic: null,
      });
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = img;
      // const isValidSize = width === 1200 && height === 800;

      // setFile(isValidSize ? file : null);
      setProfileUrl(img.src);
      setFormValues({
        ...formValues,
        profilePic: file,
      });
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let plainNumber = getPlainPhoneNumber(formValues.phoneNumber);
      const updatedFormValues = {
        ...formValues,
        phoneNumber: plainNumber,
      };
      updateUserDetails(
        updatedFormValues,
        setError,
        setLoading,
        undefined,
        undefined
      );
    }
  };

  const handleVerify = async (name) => {
    const plainNumber = getPlainPhoneNumber(formValues.phoneNumber);
    const url = "/send-verification-code";
    const isEmail = name === "email";
    let isValid = false;
    let payload = {};

    const updateErrors = (field, message) => {
      setErrors({
        ...errors,
        [field]: message,
      });
    };

    const handleResponse = (response) => {
      if (response.message) {
        setStore({
          countryDialCode: formValues.countryDialCode,
          phoneNumber: plainNumber,
          userEmail: formValues.email,
          verificationType: name,
        });
        appToast(response.message, TOAST_TYPE.SUCCESS);
        navigate("/verification", {
          state: { isEmail, diableFields: false },
        });
      }
    };

    if (isEmail) {
      const email = formValues.email;
      isValid = validateEmail(email);
      if (!isValid) {
        updateErrors("email", "Please enter a valid email address.");
        return;
      }
      payload = { email };
    } else {
      isValid = validatePhoneNumber(plainNumber);
      if (!isValid) {
        updateErrors("phone", "Not enough digits for a phone number.");
        return;
      }
      payload = {
        phoneNumber: plainNumber,
        countryDialCode: formValues.countryDialCode,
      };
    }

    const response = await APIServices.post(url, payload);
    handleResponse(response);
  };

  const HeaderContent = (
    <>
      {isPojectsAvailable ? (
        <div className="btn-group-head">
          <button className="btn-black">
            <span>03</span>PROJECTS
          </button>
          <button className="btn-black">
            <span>01</span>ON-TIME
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="head-right">
        <Dropdown className="notify-drp">
          <Dropdown.Toggle as="a" className="dropdown-toggle">
            <img
              src={notificationIcon}
              alt="comp1"
              className="notification-img"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <p>ServiceMVP website</p>
              <span>2</span>
              <img src={arrowDown} className="arrow-down" />
            </Dropdown.Item>
            <Dropdown.Item>
              <p>True grit landing page</p>
              <span>6</span>
              <img src={arrowDown} className="arrow-down" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <a>
          <img
            src={featherMenu}
            alt="menu"
            className="feather-img menuicon"
            onClick={() => {
              toggleModal("profileModal", true);
            }} // Sidebar toggle handler
          />
        </a>
      </div>
    </>
  );

  const ModalContent = (
    <>
      <div className="usee-deatils">
        {profileUrl || formValues?.profilePic ? (
          <img
            src={profileUrl || formValues?.profilePic}
            alt={ProjectListStrings.userImageAlt}
            className="user-pic"
          />
        ) : (
          <img
            src={userIcon}
            alt={ProjectListStrings.userImageAlt}
            className="user-pic"
          />
        )}
        <h2>{formValues?.fullName}</h2>
        <p>{formValues?.email}</p>
      </div>
      <div className="profile-details">
        <a
          className="edit-profile"
          onClick={() => toggleModal("updateProfile", true)}
        >
          {ProjectListStrings.editProfileText}
          {(!isVerified.isEmailVerified ||
            !isVerified.isPhoneNumberVerified) && (
            <p className="verify">{ProjectListStrings.verifyText}</p>
          )}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </a>
        {isUser1 ? (
          <>
            <a href="#">
              {ProjectListStrings.templatesLibraryText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </a>
            <a href="#">
              {ProjectListStrings.resourcesText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </a>
            <Link to="/client-directory">
              {ProjectListStrings.clientDirectoryText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </Link>
            <Link to="/myteam">
              {ProjectListStrings.myTeamText}
              <p>
                {formValues?.companyName != "undefined"
                  ? formValues?.companyName
                  : ""}
              </p>
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </Link>
          </>
        ) : (
          <>
            {" "}
            <Link to="/myteam">
              {ProjectListStrings.company}
              <p>
                {formValues?.companyName != "undefined"
                  ? formValues?.companyName
                  : ""}
              </p>
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </Link>
            <a href="#">
              {ProjectListStrings.supportText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </a>
            <a href="#">
              {ProjectListStrings.whatsNewText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </a>
          </>
        )}
        <a href="#">
          {ProjectListStrings.settingsText}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </a>
        <a
          href="#"
          className="logout"
          onClick={() => {
            logout();
          }}
        >
          {ProjectListStrings.logoutText}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </a>
      </div>
    </>
  );

  const ModalProfileContent = (
    <>
      <div className="usee-deatils">
        {(profileUrl || formValues?.profilePic) && (
          <img
            src={profileUrl || formValues?.profilePic}
            alt={ProjectListStrings.userImageAlt}
            className="user-pic"
          />
        )}
        <input
          accept="image/*"
          type="file"
          id="myFile"
          name="filename"
          onChange={handleFileChange}
        />
        <img
          src={uploadIcon}
          alt={ProjectListStrings.uploadIconAlt}
          className="user-upload"
        />
      </div>
      <div className="profile-deatils">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-group mb-3">
                <input
                  maxLength={20}
                  type="text"
                  className={`form-control ${
                    errors.fullName ? "form-invalid" : ""
                  }`}
                  placeholder={ProjectListStrings.fullNamePlaceholder}
                  name="fullName"
                  value={formValues.fullName}
                  onChange={(e) => {
                    const isValid = /^[A-Za-z\s]*$/.test(e.target.value);
                    if (isValid) {
                      handleChange(e);
                    }
                  }}
                />
                {errors.fullName && (
                  <span className="error-message">{errors.fullName}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-group mb-3">
                <input
                  maxLength={30}
                  disabled={false}
                  type="text"
                  className={`form-control ${
                    errors.email ? "form-invalid" : ""
                  }`}
                  name="email"
                  placeholder={ProjectListStrings.emailPlaceholder}
                  value={formValues.email}
                  onChange={handleChange}
                />
                {!isVerified.isEmailVerified && (
                  <p
                    className="veryfy-text"
                    onClick={() => {
                      handleVerify("email");
                    }}
                  >
                    {ProjectListStrings.phoneNumberVerifyText}
                  </p>
                )}
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-group mb-3 phone-number-group">
                <div className="phone-number-group-cover mb-10">
                  <PhoneNumberInput
                    isDisable={false}
                    value={formValues.phoneNumber}
                    onPhoneChange={(val) =>
                      handlePhoneChange(val, formValues.countryDialCode)
                    }
                    countryDialCode={formValues.countryDialCode}
                    countryName={formValues.countryCode}
                    onCountryChange={(country) => {
                      setFormValues({
                        ...formValues,
                        countryDialCode: country.dialCode,
                        countryCode: country.value,
                      });
                      handlePhoneChange(
                        formValues.phoneNumber,
                        country.dialCode
                      );
                    }}
                    errors={errors.phone}
                  />
                  {!isVerified.isPhoneNumberVerified && (
                    <p
                      className="veryfy-text"
                      onClick={() => {
                        handleVerify("phone");
                      }}
                    >
                      {ProjectListStrings.phoneNumberVerifyText}
                    </p>
                  )}
                  {errors.phone && (
                    <span className="error-message">{errors.phone}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
            type="button"
            className="btn-black"
          >
            {!loading ? ProjectListStrings.saveButtonText : selectLoader(35)}
          </button>
        </form>
      </div>
    </>
  );

  // Handle events like close or skip
  const handleJoyrideCallback = (data) => {
    const { action } = data;

    if (action == "reset" || action === "close") {
      setRunTour(false);
      let plainNumber = getPlainPhoneNumber(formValues.phoneNumber);

      const updatedFormValues = {
        ...formValues,
        isTotorialVisited: true,
        phoneNumber: plainNumber,
      };

      setFormValues(updatedFormValues);
      updateUserDetails(updatedFormValues, setError, setLoading, false);
    }
    // console.log(data); // Log the Joyride event for debugging
  };

  const handleUpdateProject = (project) => {
    navigate("/overview", {
      state: { projectId: project._id, projectDetails: project },
    });
  };
  const handleUpdateMilestone = (projectId) => {
    toggleModal("updateProject", true);
    setProjectId(projectId);
    console.log(projectId);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="wrapper">
      <div className="main-content">
        <Header content={HeaderContent} />
        {dataLoading ? (
          <div className="nodata-loader">{selectLoader(75)} </div>
        ) : isPojectsAvailable ? (
          <section className="dashboard-sec">
            <Joyride
              callback={handleJoyrideCallback}
              steps={HomeSteps}
              continuous={true}
              showSkipButton={false}
              hideCloseButton={true}
              showProgress={true}
              run={runTour}
              locale={{
                back: ProjectListStrings.prevButton,
                close: ProjectListStrings.closeButton,
                last: ProjectListStrings.lastButton,
                next: ProjectListStrings.nextButton,
                skip: ProjectListStrings.skipButton,
              }}
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-top-sec">
                    <div className="top-field-cover">
                      <div className="form-group search-box">
                        <input
                          type="search"
                          id="search-box-tour"
                          className="form-control search-box-control"
                          placeholder={ProjectListStrings.searchPlaceholder}
                        />
                        <img src={searchIcon} className="search-icon" />
                      </div>

                      <div className="form-group select-project form-group-select-icon">
                        <select
                          className="form-control select2"
                          style={{ width: "100%" }}
                        >
                          <option>
                            {ProjectListStrings.projectManagerOption}
                          </option>
                          <option>Alaska</option>
                          <option>California</option>
                          <option>Delaware</option>
                          <option>Tennessee</option>
                          <option>Texas</option>
                          <option>Washington</option>
                        </select>
                        <img
                          src={arrowDown}
                          alt="arrow icon"
                          className="arro-icon"
                        />
                      </div>

                      <div className="form-group select-status form-group-select-icon">
                        <select
                          className="form-control select2"
                          style={{ width: "100%" }}
                        >
                          <option>{ProjectListStrings.statusOption}</option>
                          <option>{ProjectListStrings.inactiveOption}</option>
                        </select>
                        <img
                          src={arrowDown}
                          alt="arrow icon"
                          className="arro-icon"
                        />
                      </div>

                      <div className="form-group select-recent form-group-select-icon">
                        <select
                          className="form-control select2"
                          style={{ width: "100%" }}
                        >
                          <option>{ProjectListStrings.recentOption}</option>
                          <option>Alaska</option>
                          <option>California</option>
                          <option>Delaware</option>
                          <option>Tennessee</option>
                          <option>Texas</option>
                          <option>Washington</option>
                        </select>
                        <img
                          src={arrowDown}
                          alt="arrow icon"
                          className="arro-icon"
                        />
                      </div>
                    </div>

                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="false"
                          onClick={() => handleTabChange("pills-home")}
                        >
                          <img
                            src={
                              activeTab == "pills-home"
                                ? listWhiteIcon
                                : listIcon
                            }
                            className="list-icon"
                            alt={ProjectListStrings.homeTabAlt}
                          />
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="true"
                          onClick={() => handleTabChange("pills-profile")}
                        >
                          <img
                            src={
                              activeTab == "pills-profile"
                                ? gridWhiteIcon
                                : gridIcon
                            }
                            className="home-icon"
                            alt={ProjectListStrings.profileTabAlt}
                          />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="project-list">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  {pageLoading ? (
                                    <div className="nodata-loader">
                                      {selectLoader(70)}
                                    </div>
                                  ) : (
                                    projects.map((project) => (
                                      <tr
                                        className="bottom-spacing"
                                        key={project._id}
                                      >
                                        <td
                                          className="cursor_pointer"
                                          onClick={() =>
                                            handleUpdateProject(project)
                                          }
                                          style={{ width: "350px" }}
                                        >
                                          <p>{project.projectName}</p>
                                        </td>
                                        <td>
                                          <p>Design</p>
                                        </td>
                                        <td style={{ minWidth: "350px" }}>
                                          <div className="milestone-cover">
                                            <h5>
                                              {formatDate(
                                                project?.startDate || new Date()
                                              )}
                                            </h5>
                                            <div className="milestone-process">
                                              <div className="milestone-process-bar">
                                                <span
                                                  style={{
                                                    left: `calc(${100}% - 16px)`,
                                                  }}
                                                >
                                                  40%
                                                </span>
                                                <ProgressBar now={40} />
                                              </div>
                                            </div>
                                            <h5>
                                              {formatDate(
                                                project?.endDate || new Date()
                                              )}
                                            </h5>
                                          </div>
                                        </td>
                                        <td>
                                          <button
                                            className="updated-btn"
                                            onClick={() => {
                                              handleUpdateMilestone(
                                                project._id
                                              );
                                            }}
                                          >
                                            Update
                                          </button>
                                        </td>
                                        <td>
                                          <small>76%</small>
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          <p className="text-success text-center">
                                            {ProjectListStrings.onTimeTag}
                                          </p>
                                        </td>
                                        <td style={{ width: "12%" }}>
                                          <div className="d-flex align-items-center justify-content-end">
                                            <div className="me-2">
                                              <img
                                                src={chatBoxes}
                                                style={{ maxWidth: "none" }}
                                              />
                                            </div>
                                            <h4 className="mb-0">
                                              6{" "}
                                              {ProjectListStrings.updatesCount}
                                            </h4>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade show active"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="row">
                        {pageLoading ? (
                          <div className="nodata-loader">
                            {selectLoader(70)}{" "}
                          </div>
                        ) : (
                          projects.map((project) => (
                            <div
                              className="col-lg-4 col-md-6 mt-3"
                              key={project._id}
                            >
                              <div
                                className="card-box cursor_pointer"
                                onClick={() => handleUpdateProject(project)}
                              >
                                <div className="inner-card-box">
                                  <div className="title-content">
                                    <h3
                                      className="cursor_pointer"
                                    >
                                      {project.projectName}
                                    </h3>
                                    {isUser1 && (
                                      <button
                                        className="updated-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleUpdateMilestone(project._id);
                                        }}
                                      >
                                        Update
                                      </button>
                                    )}
                                  </div>
                                  <div className="time-details">
                                    <h3>#on-time</h3>
                                    <h6>Sign-Off Requested</h6>
                                  </div>
                                  <div
                                    className="process-cover"
                                    id="process-tour"
                                  >
                                    <p>Development 60%</p>
                                    <ProgressBar now={60} />
                                  </div>
                                  <p className="milestone-text">
                                    Next Milestone: QA Approval
                                  </p>
                                  <div className="milestone-cover">
                                    <h5>
                                      {formatDate(
                                        project?.startDate || new Date()
                                      )}
                                    </h5>
                                    <div className="milestone-process">
                                      <div className="milestone-process-bar">
                                        <span
                                          style={{
                                            left: `calc(${100}% - 16px)`,
                                          }}
                                        >
                                          40%
                                        </span>
                                        <ProgressBar now={40} />
                                      </div>
                                    </div>
                                    <h5>
                                      {formatDate(
                                        project?.endDate || new Date()
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <div className="card-bottom">
                                  <div className="comment-content">
                                    <div className="d-flex">
                                      <div className="comment-chat">
                                        <img src={chatBoxes} alt="Chat Icon" />
                                      </div>
                                      <h5>2 Updates</h5>
                                    </div>
                                    <h5>
                                      Modified by Robert, April 27
                                      {/* Modify by {"Ankit"},{" "}
                                      {formatDate(
                                        project.organizationId.updatedAt
                                      )} */}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ActionMenu
              extraClass="options-list-comman"
              links={
                isUser1
                  ? [
                      {
                        text: ProjectListStrings.createProjectText,
                        className: "create_project",
                        linkto: "/create-project",
                      },
                      {
                        text: ProjectListStrings.createTeamText,
                        className: "create_team",
                        linkto: "/myteam",
                      },
                      {
                        text: ProjectListStrings.createTaskText,
                        className: "create_task",
                        linkto: "/create-project",
                      },
                    ]
                  : [
                      {
                        text: ProjectListStrings.addTeam,
                        className: "create_project",
                        linkto: "/myteam",
                      },
                    ]
              }
              buttonIcon="fas fa-plus"
              buttonStyles={{ backgroundColor: "blue", color: "white" }}
              showItem={!formValues?.isTotorialVisited}
            />
          </section>
        ) : (
          <section className="blank-sec dashboard-sec">
            {/* <Joyride
              callback={handleJoyrideCallback}
              steps={BlankHomeSteps}
              continuous={true}
              showSkipButton={false}
              hideCloseButton={true}
              showProgress={true}
              run={runTour}
              locale={{
                back: ProjectListStrings.prevButton,
                close: ProjectListStrings.closeButton,
                last: ProjectListStrings.lastButton,
                next: ProjectListStrings.nextButton,
                skip: ProjectListStrings.skipButton,
              }}
            /> */}
            <div className="container">
              <div className="row align-items-start">
                <div className="col-lg-12">
                  <div className="blankList-box-cover">
                    <div className="blankList-box">
                      <img className="akarFile" src={akarFile} />
                      <h2>{ProjectListStrings.noProjectsTitle}</h2>
                      {isUser1 && (
                        <p>{ProjectListStrings.noProjectsDescription}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isUser1 && (
              <ActionMenu
                extraClass="options-list-comman"
                links={[
                  {
                    text: ProjectListStrings.createProjectText,
                    className: "create_project",
                    linkto: "/create-project",
                  },
                  {
                    text: ProjectListStrings.createTeamText,
                    className: "create_team",
                    linkto: "/myteam",
                  },
                  {
                    text: ProjectListStrings.createTaskText,
                    className: "create_task",
                    linkto: "/create-project",
                  },
                ]}
                buttonIcon="fas fa-plus"
                buttonStyles={{ backgroundColor: "blue", color: "white" }}
                showItem={false}
              />
            )}
          </section>
        )}
        {/* Modal Component */}
        <SidebarModal
          show={modals.profileModal}
          onHide={() => toggleModal("profileModal", false)}
        >
          {ModalContent}
        </SidebarModal>

        <SidebarModal
          extraClass={"edit-profile-modal"}
          show={modals.updateProfile}
          onHide={() => toggleModal("updateProfile", false)}
          headerTitle={ProjectListStrings.editProfileHeader}
        >
          {ModalProfileContent}
        </SidebarModal>
        <SidebarModal
          extraClass={"edit-profile-modal"}
          show={modals.updateProject}
          onHide={() => toggleModal("updateProject", false)}
          headerTitle={"Add Update"}
        >
          {/* {UpdateProjectForm(projectId)} */}
          <UpdateProjectForm
            projectId={projectId}
            closeModal={() => toggleModal("updateProject", false)}
          />
        </SidebarModal>
      </div>
    </div>
  );
};

export default Home;
