import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client"; // Import socket.io-client
import uploadSvg from "../../../assets/img/upload.svg";
import sendSvg from "../../../assets/img/send.svg";
import SidebarModal from "../../../components/SidebarModal";
import rightArrowIcon from "../../../assets/img/right-arrow.svg";
import {
  changeOrderModalContent,
  requestFeedbackModalContent,
  requestModalContent,
  signOffModalContent,
} from "../../../utils/modalContent";
import UpdateProjectForm from "../forms/UpdateProjectForm";
import { useAuth } from "../../../contexts/AuthContext";
import APIServices from "../../../services/APIServices";
import { selectLoader } from "../../../utils/Helpers";
import SignOffForm from "../forms/SignOffForm";
const ActivityTab = ({ projectId ,updateProjectDetails}) => {
  const scrollRef = useRef(null);
  const { user } = useAuth();
  const [message, setMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modals, setModals] = useState({
    updateModal: false,
    signOffModal: false,
    changeOrderModal: false,
    requestModal: false,
    requestFeedbackModal: false,
    addQuoteModal: false,
  });

  const fetchActivities = async (projectId) => {
    try {
      setLoading(true);
      const response = await APIServices.get(
        `/project-activity/${projectId}?page=1&limit=10`
      );
      if (response.currentPage) {
        let reverseArray = response.data.reverse();
        setActivities(reverseArray); // assuming data.data contains the activities array
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen,
    }));
    if (isOpen == false) {
      fetchActivities(projectId);
      updateProjectDetails();
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchActivities(projectId);
    }
  }, [projectId]);

  // Scroll to the bottom whenever activities change
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [activities]);
  // useEffect(() => {
  //   if (user) {
  //     let userId = user._id;
  //     const newSocket = io("https://api.happyending.ai", {
  //       query: { userId },
  //     });
  // setSocket(newSocket);

  //     // // Clean up the socket connection when component unmounts
  //     // return () => {
  //     //   newSocket.disconnect();
  //     // };
  //   }
  // }, [user]);

  // const sendMessage = () => {
  //   if (socket) {
  //     if (message && message.length > 0) {
  //       socket.emit("projectActivityUpdate", { Message: message });
  //       setMessage("");
  //     } else {
  //       alert("Please Write a message");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   // Listen for 'projectActivityUpdate' event
  //   if (socket) {
  //     socket.on("projectActivityUpdate", (activity) => {
  //       // setActivities((prevActivities) => [activity, ...prevActivities]);
  //       console.log("activity", activity);
  //     });
  //   }
  // }, [socket]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();

    // Reset today's date to midnight for easier comparison
    today.setHours(0, 0, 0, 0);

    // Calculate yesterday by subtracting one day from today's midnight time
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Determine if the date is today, yesterday, or neither
    if (date >= today) {
      return `- ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })} Today`;
    } else if (date >= yesterday) {
      return `- ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })} Yesterday`;
    } else {
      return `- ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })} ${date.toLocaleDateString()}`;
    }
  };

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="card-body-content" ref={scrollRef}>
      {/* {loading ? selectLoader(80) :
    } */}

      {/* <div>
        <input
        placeholder="send message"
        onChange={(e) => {
            setMessage(e.target.value);
          }}
          />
          <button 
          // onClick={sendMessage}
          >Submit</button>
          </div> */}
      {loading ? (
        <div className="nodata-loader">{selectLoader(50)}</div>
      ) : (
        activities.map((activity) => (
          <div key={activity._id} className="chat-bubbleMe-cover">
            <div className="lightbg-card chat-bubble">
              <p>
                <b className="darkgreen-text">Project Update!</b>{" "}
                <b className="lightblue-text">
                  {/* @{activity.createdBy.email .split("")[0]} */}@all
                  {/* {activity.createdBy.fullName} */}
                </b>{" "}
                {activity.createdBy.fullName} has updated the project.
              </p>

              <ul>
                <li>
                  <b>{activity.milestoneId.name} </b>% completed from{" "}
                  <b>{activity.activityTypeId.fromPercentage}%</b> to{" "}
                  <b>{activity.activityTypeId.toPercentage}%</b>
                </li>
                {/* <li>
                  Milestone changed from{" "}
                  <b>
                    {activity.milestoneId.name}
                    <img
                      src={rightArrowIcon}
                      className="right-icon"
                      width="21"
                      height="12"
                      alt="Right Arrow Icon"
                    />
                    Development
                  </b>
                </li> */}
                <li>
                  <b className="mediumblack-text">Project status is:</b>{" "}
                  {activity?.activityTypeId?.updateStatus?.value ==
                    "ontime" && <b className="darkgreen-text">On Time</b>}
                  {activity?.activityTypeId?.updateStatus?.value == "early" && (
                    <b className="darkblue-text">Early</b>
                  )}
                  {/* <b className="darkgreen-text">/</b> */}
                  {activity?.activityTypeId?.updateStatus?.value == "late" && (
                    <b className="red-text">Late</b>
                  )}
                </li>
              </ul>
              <p className="mt-3">
                <b >Note:</b>{" "}
                {activity.activityTypeId.updateNote}
              </p>
            </div>
            <div className="chat-time">
              {activity.createdBy.fullName}{" "}
              {/* {activity.createdBy.email.split("@")[0]} -{" "} */}
              {formatTime(activity.createdDate)}
            </div>
          </div>
        ))
      )}

      {/* <div className="chat-bubbleMe-cover">
        <div className="bluebg-card chat-bubble">
        <p>
        <b className="darkblue-text">New Request!</b>
        <b className="lightblue-text"> @Rocco</b> has documented a
        <a href="#">request.</a>
        </p>
        </div>
        
        <div className="chat-time">Rocco - 9:46 AM Today</div>
        </div>
      
        <div className="chat-bubbleYou-cover">
        <div className="bluebg-card chat-bubble">
        <p>
        <b className="darkblue-text">New Request! </b>
            <b className="lightblue-text">@Ravi </b> has submitted a
            <a href="#"> request.</a>
          </p>
          </div>

          <div className="chat-time">Ravi - 9:47 AM Today</div>
          </div>
          
          <div className="chat-bubbleMe-cover">
          <div className="purplebg-card chat-bubble">
          <p>
          <b className="darkpurple-text">Change Order!</b>
          <b className="lightblue-text"> @Rocco</b> has documented a
            <a href="#">change order.</a>
            </p>
            </div>
            
            <div className="chat-time">Rocco - 9:48 AM Today</div>
            </div> */}
      <div className="user-chatBox-cover">
        <ul className="chat-btn-list">
          <li>
            <a
              className="cursor_pointer"
              onClick={() => toggleModal("updateModal", true)}
            >
              + Update
            </a>
          </li>
          <li>
            <a
              className="cursor_pointer"
              onClick={() => toggleModal("signOffModal", true)}
            >
              + Sign-off
            </a>
          </li>

          <li>
            <a
              className="cursor_pointer"
              onClick={() => toggleModal("changeOrderModal", true)}
            >
              + Change Order
            </a>
          </li>
          <li>
            <a
              className="cursor_pointer"
              onClick={() => toggleModal("requestModal", true)}
            >
              + Request
            </a>
          </li>
          <li>
            <a
              className="cursor_pointer"
              onClick={() => toggleModal("requestFeedbackModal", true)}
            >
              + Request Feedback
            </a>
          </li>
        </ul>
        <div className="chat-msg-cover">
          <div className="send-msg-text">
            <img src={uploadSvg} className="upload-icon" />
            <textarea
              id="autoResizableTextarea"
              className="formControl"
              placeholder="Type your message"
            />
            <div className="send-msg-cover">
              <img src={sendSvg} />
            </div>
          </div>
        </div>
      </div>

      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.changeOrderModal}
        onHide={() => toggleModal("changeOrderModal", false)}
        headerTitle={"Change Order"}
      >
        {changeOrderModalContent()}
      </SidebarModal>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.requestModal}
        onHide={() => toggleModal("requestModal", false)}
        headerTitle={"New Request"}
      >
        {requestModalContent()}
      </SidebarModal>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.requestFeedbackModal}
        onHide={() => toggleModal("requestFeedbackModal", false)}
        headerTitle={"Request Feedback"}
      >
        {requestFeedbackModalContent([])}
      </SidebarModal>

      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.updateModal}
        onHide={() => toggleModal("updateModal", false)}
        headerTitle={"Add Update"}
      >
        {
          <UpdateProjectForm
            projectId={projectId}
            closeModal={() => {
              toggleModal("updateModal", false);
            }}
          />
        }
      </SidebarModal>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.signOffModal}
        onHide={() => toggleModal("signOffModal", false)}
        headerTitle={"Sign-off Request"}
      >
        {signOffModalContent([], [], [])}
        {/* <SignOffForm
          projectId={projectId}
          closeModal={() => {
            toggleModal("updateModal", false);
          }}
        /> */}
      </SidebarModal>
    </div>
  );
};

export default ActivityTab;
