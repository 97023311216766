import React, { useState, useEffect, useMemo, useRef } from "react";
import Select, { components } from "react-select";
import "flag-icon-css/css/flag-icons.min.css";
import "../assets/css/signup.css"; // Custom styles
import { countryOptions } from "../utils/Helpers";

const PhoneNumberInput = ({
  value,
  onPhoneChange,
  countryDialCode,
  countryName,
  onCountryChange,
  errors,
  isDisable = false,
}) => {
  const defaultCountry =
    countryOptions.find((option) => option.value === "US") || countryOptions[0];

  const [selectedCountry, setSelectedCountry] = useState(
    countryOptions.find((option) => option.value === countryName) ||
      defaultCountry
  );
  const [phoneNumber, setPhoneNumber] = useState(value || "");
  const [customInput, setCustomInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(countryOptions);
  
  useEffect(() => {
    onPhoneChange(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    onCountryChange(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    setPhoneNumber(value || "");
  }, [value]);

  useEffect(() => {
    if (countryName !== "") {
      const newSelectedCountry = countryOptions.find(
        (option) => option.value === countryName
      );
      if (newSelectedCountry) {
        setSelectedCountry(newSelectedCountry);
      }
    }
  }, [countryName]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;
    // Remove all non-numeric characters
  const numericInput = input.replace(/[^0-9]/g, "").slice(0, 14); // Limit to 14 digits

  // Format the phone number as (XXX) XXX-XXXX
  let formattedNumber = numericInput;

  if (numericInput.length > 3 && numericInput.length <= 6) {
    // Format as (XXX) XXX
    formattedNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3)}`;
  } else if (numericInput.length > 6) {
    // Format as (XXX) XXX-XXXX
    formattedNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 14)}`;
  }
    
    setPhoneNumber(formattedNumber);
  };

  const handleCustomInputChange = (event) => {
    const inputValue = event?.target?.value || "";
    setCustomInput(inputValue);

    // Trim leading and trailing spaces for filtering
    const trimmedInput = inputValue.trim();

    // Check if the trimmed input is empty
    const shouldFilter = trimmedInput.length > 0;

    const filtered = shouldFilter
      ? countryOptions.filter((option) =>
          option.label.toLowerCase().includes(trimmedInput.toLowerCase())
        )
      : countryOptions; // Return all options if input is empty or only spaces

    setFilteredOptions(filtered);
  };

  const Option = useMemo(
    () => (props) =>
      (
        <components.Option {...props}>
          <span className={props.data.flag} style={{ marginRight: 10 }}></span>
          {props.data.name} ({props.data.dialCode})
        </components.Option>
      ),
    []
  );

  const SingleValue = useMemo(
    () =>
      ({ data }) =>
        (
          <div>
            <span className={data.flag} style={{ marginRight: 10 }}></span>
            {data.dialCode}
          </div>
        ),
    []
  );

  const MenuControl = (props) => {
    const { customInput, handleCustomInputChange, ...rest } = props;
    const children = useMemo(() => rest.children, [rest.children]);

    // Create a ref for the input field
    const inputRef = useRef(null);

    // Focus the input field when the component mounts
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    // Handle key down events
    const handleKeyDown = (e) => {
      if (e.key === "Backspace" || e.key === " ") {
        // Prevent react-select from handling the backspace event
        e.stopPropagation();
      }
    };

    return (
      <components.Menu {...rest}>
        <div
          style={{ padding: "8px 10px" }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder="Enter custom code or search"
            value={customInput}
            onChange={handleCustomInputChange}
            onKeyDown={handleKeyDown}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
          />
        </div>
        {children}
      </components.Menu>
    );
  };

  return (
    <div className="phone-number-input">
      <div className={`input-container ${errors ? "form-invalid" : ""} `}>
        <Select
          isDisabled={isDisable}
          className="country-select"
          options={filteredOptions}
          value={selectedCountry}
          onChange={handleCountryChange}
          isSearchable={true}
          onMenuClose={handleCustomInputChange}
          components={{
            Option,
            SingleValue,
            Menu: (props) => (
              <MenuControl
                {...props}
                customInput={customInput}
                handleCustomInputChange={handleCustomInputChange}
              />
            ),
            IndicatorSeparator: () => null,
          }}
        />
        <input
          maxLength={16}
          type="text"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Phone number"
          className={`phone-input form-control form-control-desable ${
            isDisable ? "input-desable" : ""
          }`}
          disabled={isDisable}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
