import React, { useEffect, useState } from "react";
import Select from "react-select";
import APIServices from "../../../services/APIServices";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { fetchProjectMilestones } from "../../../utils/fetchData";
import { formatDate, selectLoader } from "../../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";

const UpdateProjectForm = ({ projectId, closeModal }) => {
  const [mileStonesList, setMileStonesList] = useState([]);
  const [mileStones, setMileStones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentMileStone, setCurrentMileStone] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("ontime");
  const [errors, setErrors] = useState({});

  // Single state object to hold all form fields
  const [formData, setFormData] = useState({
    projectId: "",
    mileStoneId: "",
    status: "",
    daysLate: 0,
    daysEarly: 0,
    fromPercentage: 0,
    toPercentage: 0,
    updateNote: "",
    notifyClient: false,
  });

  useEffect(() => {
    if (currentMileStone) {
      // console.log(currentMileStone);
      let latestUpdates = currentMileStone?.latestUpdate[0];
      setFormData((prevData) => ({
        ...prevData,
        fromPercentage: currentMileStone?.percentage,
        toPercentage: currentMileStone?.percentage,
        daysEarly: latestUpdates?.daysEarly,
        daysLate: latestUpdates?.daysLate,
        status: latestUpdates?.updateStatus?._id || formData.status,
      }));
      if (latestUpdates?.updateStatus?.value)
        setCurrentStatus(latestUpdates?.updateStatus?.value);
    }
  }, [currentMileStone]);

  const fetchMilestones = async () => {
    setLoading(true);
    try {
      let milestones = await fetchProjectMilestones(projectId);
      // if (milestones) {
      setMileStones(milestones);
      fetchStatusOptions();
      // }
    } catch (error) {
      console.error("Error fetching milestones:", error);
      setLoading(false);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await APIServices.get(`/enum?item_type=projectupdate`);
      if (response.length > 0) {
        setStatusOptions(response);
        let onTimeStatus = response.find((x) => x.value == "ontime");
        setFormData((prevData) => ({
          ...prevData,
          status: onTimeStatus?._id,
        }));
        currentStatus(onTimeStatus.value);
      }
    } catch (error) {
      console.error("Error fetching status options:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMilestones();
  }, []);

  useEffect(() => {
    if (mileStones?.length > 0) {
      const formattedPermissions = mileStones.map((option) => ({
        value: option?._id,
        label: option?.name,
      }));
      setMileStonesList(formattedPermissions);
    }
  }, [mileStones]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSelectChange = (selected, name) => {
    setFormData((prevData) => ({
      ...prevData,
      milestoneId: selected ? selected.value : "",
    }));

    const currentMilestone = mileStones.find((x) => x._id === selected?.value);
    setCurrentMileStone(currentMilestone);

    if (errors.milestoneId) {
      setErrors((prevErrors) => ({ ...prevErrors, milestoneId: "" }));
    }
  };

  const handleRangeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      toPercentage: e[1],
    }));
  };

  const handleRadioChange = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      status: option._id,
      daysEarly: 0,
      daysLate: 0,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      status: "",
      numberofDays: "",
    }));
    setCurrentStatus(option.value);
  };

  const getStatusName = (currentStatus) => {
    if (currentStatus == "late") {
      return "daysLate";
    } else if (currentStatus == "early") {
      return "daysEarly";
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.milestoneId) newErrors.milestoneId = "Milestone is required.";
    if (!formData.status) newErrors.status = "Status is required.";
    if (currentStatus == "late" && !formData.daysLate)
      newErrors.numberofDays = "Please Enter Number of days.";
    if (currentStatus == "early" && !formData.daysEarly)
      newErrors.numberofDays = "Please Enter Number of days early.";
    if (!formData.updateNote.trim())
      newErrors.updateNote = "Update note cannot be empty.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setSubmitLoading(true);
      const payload = {
        projectId: projectId,
        milestoneId: formData.milestoneId,
        updateStatus: formData.status,
        daysLate: Number(formData.daysLate) || 0,
        daysEarly: Number(formData.daysEarly) || 0,
        fromPercentage: formData.fromPercentage,
        toPercentage: formData.toPercentage,
        updateNote: formData.updateNote,
        notifyClient: formData.notifyClient,
      };
      try {
        const response = await APIServices.post("/project-updates", payload);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          closeModal();
        }
      } catch (err) {
        console.error("Error while verifying email", err);
      } finally {
        setSubmitLoading(false);
      }
    } else {
      console.log("Form has validation errors.");
    }
  };

  return (
    <div className="updatemodal">
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className={`form-control select2 form-control-select ${
                errors.milestoneId ? "form-invalid" : ""
              }`}
              options={mileStonesList}
              placeholder="Select Milestone"
              isClearable
              onChange={handleSelectChange}
              isLoading={loading}
            />
            {errors.milestoneId && (
              <p className="error-message ml-2">{errors.milestoneId}</p>
            )}
          </div>
        </div>
      </div>

      {currentMileStone?.dueDate && (
        <p className="due-date">
          Due Date, {formatDate(currentMileStone?.dueDate || new Date())}
        </p>
      )}
      <div className="row">
        <div className="col-lg-12">
          <div
            className={`form-group-boxbg  form-group ${
              errors.status ? "form-invalid" : ""
            }`}
          >
            {loading ? (
              <div className="text-center w-100">{selectLoader(30)}</div>
            ) : (
              statusOptions?.map((option) => (
                <label key={option._id} className="radio-button-container">
                  {option.name}
                  <input
                    type="radio"
                    checked={formData.status === option._id}
                    onChange={() => {
                      handleRadioChange(option);
                    }}
                    name="status"
                  />
                  <span className="checkmark"></span>
                </label>
              ))
            )}
            {errors.status && (
              <p className="error-message ml-2" style={{ buttom: "-15px" }}>
                {errors.status}
              </p>
            )}
          </div>
        </div>
      </div>

      {currentStatus !== "ontime" && (
        <div className="row ">
          <div className="col-lg-12">
            <div className="form-group ">
              <input
                value={
                  currentStatus == "late"
                    ? formData.daysLate
                    : formData.daysEarly
                }
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    // Accepts only digits
                    handleInputChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      numberofDays: "",
                    }));
                  }
                }}
                type="text"
                className={`form-control ${
                  errors.numberofDays ? "form-invalid" : ""
                }`}
                name={getStatusName(currentStatus)}
                placeholder={`Number of days ${currentStatus}`}
              />
              {errors.numberofDays && (
                <p className="error-message ml-2">{errors.numberofDays}</p>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="mt-3">
        <RangeSlider
          onInput={handleRangeChange}
          className="range-slider"
          value={[0, formData.toPercentage]}
          
        />
      </div>
      {/* {errors.toPercentage && (
        <p className="error-text">{errors.toPercentage}</p>
      )} */}

      <h3 className="process-parsentage">{formData.toPercentage}%</h3>

      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              style={{ minHeight: "120px" }}
              className={`form-control ${
                errors.updateNote ? "form-invalid" : ""
              }`}
              name="updateNote"
              value={formData.updateNote}
              onChange={handleInputChange}
              placeholder="Write update note"
            ></textarea>
            {errors.updateNote && (
              <p className="error-message ml-2">{errors.updateNote}</p>
            )}
          </div>
        </div>
      </div>

      <div className="switch-cover">
        <h4>Notify Client</h4>
        <div className="form-switch">
          <input
            type="checkbox"
            checked={formData.notifyClient}
            onChange={handleInputChange}
            name="notifyClient"
            hidden="hidden"
            id="projectdelay"
          />
          <label className="switch" htmlFor="projectdelay"></label>
        </div>
      </div>
      <button className="btn-black" onClick={handleSubmit}>
        {!submitLoading ? "Update" : selectLoader(35)}
      </button>
    </div>
  );
};

export default UpdateProjectForm;
